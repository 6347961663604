import { CloseCircleOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Button, FormControl, Input, InputLabel, Tooltip } from "@mui/material";
import React, { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { handleFieldData } from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import Webcame from "./Webcam";
import { CameraAltOutlined } from "@mui/icons-material";

const FileField = ({
  data,
  step,
  value,
  status,
  loading,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
  loading?: boolean;
}) => {
  const dispatch = useDispatch();
  const fileRef = React.useRef<any>(null);
  const [fieldValue, setFieldValue] = React.useState(value);
  const [open, setOpen] = React.useState(false);
  const reset = useAppSelector((state: any) => state.inspection).toggle;
  const { focus } = useAppSelector((state: any) => state.inspection);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset, loading]);

  const handleSave = () => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
  };

  const handleUpload = () => {
    if (fileRef) {
      fileRef.current.click();
    }
  };

  useEffect(() => {
    if (fieldValue) {
      handleSave();
    }
  }, [fieldValue]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files;
    if (file?.length > 0) {
      if (file[0]?.type.startsWith("image/")) {
        setFieldValue(file[0]);
      } else {
        return;
      }
    }
  };

  const handleRemoveFile = () => {
    fileRef.current.value = null;
    setFieldValue(null);
  };

  useEffect(() => {
    if (nextButtonRef.current) {
      setTimeout(() => {
        nextButtonRef.current?.focus();
      }, 50);
    }
  }, [focus]);

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
      </InputLabel>
      <input
        hidden
        type="file"
        id={data.key}
        onChange={handleFileUpload}
        ref={fileRef}
      />

      {fieldValue ? (
        <div>
          <p>{fieldValue.name}</p>
          <CloseCircleOutlined onClick={handleRemoveFile} />
        </div>
      ) : (
        <div style={{ border: "none", paddingInline: "0" }}>
          {" "}
          <Button variant="contained" onClick={handleUpload} disabled={!status}>
            <CloudUploadOutlined />
            Upload
          </Button>
          <Button
            ref={nextButtonRef}
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            disabled={!status}
          >
            <CameraAltOutlined style={{ marginRight: "4px" }} />
            Take
          </Button>{" "}
        </div>
      )}
      {!fieldValue ? (
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={fieldValue ? false : true}
        >
          Save
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={handleRemoveFile}
          disabled={fieldValue ? false : true}
        >
          Retry
        </Button>
      )}
      <Webcame
        open={open}
        setOpen={setOpen}
        setFieldValue={setFieldValue}
        fieldValue={fieldValue}
      />
    </FormControl>
  );
};

export default memo(FileField);
