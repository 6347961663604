import { Backdrop, Box, Button, Fade, Modal } from "@mui/material";

type ModelProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  validation?: any;
};

const styleGreen = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  maxHeight: "85%",
  background: "green",
  borderRadius: "10px",
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
  outline: "none",
  overflow: "hidden",
  overflowY: "scroll",
  scrollbarWidth: "none",
  scrollbarColor: "transparent transparent",
  p: 4,
};

const styleRed = {
  ...styleGreen,
  background: "red",
};

export const QIS4eligibleModel = ({
  open,
  setOpen,
  validation,
}: ModelProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={validation?.flag === "GREEN" ? styleGreen : styleRed}>
          <div
            style={{
              margin: "10px",
              textAlign: "center",
              color: "white",
            }}
          >
            {validation?.flag === "GREEN"
              ? `${validation?.packId} is eligible for pack packaging`
              : `${validation?.packId} is not eligible for pack packaging`}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              variant="contained"
              onClick={() => setOpen(false)}
              style={{ marginLeft: "7px", marginBottom: "10px" }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
