import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import { store } from "./store";
import { ToastContainer } from "react-toastify";

import "swiper/css";
import "swiper/css/navigation";
import "./assets/css/index.css";
import "react-toastify/dist/ReactToastify.css";
import ScrollTop from "./components/ScrollTop";
import Routes from "./routes/indexHash";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <HashRouter>
        <React.Fragment>
          <ScrollTop>
            <Routes />
          </ScrollTop>
        </React.Fragment>
      </HashRouter>
    </Provider>
  </StyledEngineProvider>
);
