import React from "react";
import { QisDownload } from "./QisDownload";

function QisReportDownload() {
  return (
    <div style={{ marginTop: "-100vh", minWidth: "1024px" }}>
      <QisDownload />
    </div>
  );
}

export default QisReportDownload;
