import React, { useState } from "react";
import SopHeader from "./StepForms/sopHeader";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import QISInput from "./fieldComponants/QISInput";
import { getPassport } from "../../service/apiService";
import ReportPDF_V2_Customer from "./ReportPDF_V2 customer portal";

const CustomerPortal = () => {
  const [qisId, setQisId] = useState("");
  const [data, setData] = useState("");

  const handlePassportData = () => {
    getPassport(qisId)
      .then((res: any) => {
        if (res?.success) {
          setData(res?.data?.data);
        }
      })
      .catch((e) => console.log("e=>", e));
  };

  React.useEffect(() => {
    if (qisId) handlePassportData();
  }, [qisId]);
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner" id="kpi">
        <div className="sm-stepForm-banner">
          <SopHeader title={"Customer Portal"} link={""} />
        </div>

        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box common common-kpi">
              <div className="graph_heading">
                {/* <h4>Customer Portal</h4> */}
              </div>

              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">Scan Pack ID</InputLabel>{" "}
                <QISInput
                  status={true}
                  data={{
                    key: "QIS",
                    name: "Scan Pack ID",
                    type: "fieldQIS",
                  }}
                  step={"step_22"}
                  // loading={loadingGet}
                  value={qisId}
                  key={"QIS"}
                  setQisId={setQisId}
                  // handleClick={handleGetData}
                />
                {/* <Button variant="contained">Save</Button> */}
              </FormControl>
              {/* <FormControl variant="standard" className="sm-form-control"> */}
              {data && (
                <div className="sm-commen-box common common-kpi">
                  <ReportPDF_V2_Customer data={data} />
                </div>
              )}
              {/* </FormControl> */}
              {/* <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  ColumbAI Link
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">Order Status</InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  IT Ticket & Request Form
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  Points of Contact
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  Customer Communication/Feedback
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">FAQ Section</InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  Registration form
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CustomerPortal;
