import {
  Button,
  Checkbox,
  Grid,
  Select as MuiSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import ReactApexChart from "react-apexcharts";
import jsPDF from "jspdf";

import top from "../../../../../assets/images/AAD/top.jpg";
import bottom from "../../../../../assets/images/AAD/bottom.jpg";
import front from "../../../../../assets/images/AAD/front.jpg";
import back from "../../../../../assets/images/AAD/back.jpg";
import right from "../../../../../assets/images/AAD/right.jpg";
import left from "../../../../../assets/images/AAD/left.jpg";
import top2 from "../../../../../assets/images/AAB/top2.jpg";
import bottom2 from "../../../../../assets/images/AAB/bottom2.jpg";
import front2 from "../../../../../assets/images/AAB/front2.jpg";
import back2 from "../../../../../assets/images/AAB/back2.jpg";
import right2 from "../../../../../assets/images/AAB/right2.jpg";
import left2 from "../../../../../assets/images/AAB/left2.jpg";
import generatePDF, { Options } from "react-to-pdf";
import { getPackIdDataReport } from "../../../../../service/apiService";
import html2canvas from "html2canvas";
import Header from "../Header";
import {
  reversedData,
  VoltageData,
  DVA,
  nyquist,
  VoltageData2,
  findPeaks,
  graphData,
} from "../Graphdata";
import { exportMultipleChartsToPdf } from "../Utils";
import { useParams } from "react-router-dom";
import Favicon from "react-favicon";
import favicon from "../../../../../assets/svg/favicon.png";
import favicon2 from "../../../../../assets/svg/loading-gif.gif";
import moment from "moment";

const graphArray: any = {
  his4: {
    category: "single",
    xAxis: "DataPoint",
    yAxis: "Voltage(V)",
  },
  his5: {
    category: "dual",
    xAxis: "Zreal (ohm)",
    yAxis: [" -Zimag (ohm)", " Fit1 - -Zimag (ohm)"],
  },
  his6: {
    category: "single",
    xAxis: "Capacity(Ah)",
    yAxis: "Voltage(V)",
  },
  his62: {
    category: "single",
    xAxis: "Capacity (Ah)",
    yAxis: "dQ/dV (Ah/V)",
  },
};

export const QisDownload = () => {
  const defaultFavicon = favicon;
  const animatedFavicon = favicon2;
  const [open, setOpen] = React.useState<boolean>(false);
  const [hisMesuments, setHISMesurement] = React.useState<any>([]);
  const [hisDataURI, setHisDataURI] = React.useState<any>(null);
  const [pdf, setPdf] = React.useState<any>(null);
  const [hisSkip, setHisSkip] = React.useState<any>(false);
  console.log("%c Line:92 🍰 pdf", "color:#f5ce50", pdf, hisSkip);
  const [loading, setLoading] = React.useState<any>(false);
  const [moduleId, setModuleId] = useState<any>("");
  const module_id = useParams().id;
  useEffect(() => {
    setModuleId(module_id);
  }, [module_id]);

  const page1 = useRef<any>(null);
  const page2 = useRef<any>(null);
  const page3 = useRef<any>(null);
  const page4 = useRef<any>(null);

  const [state2, setState2] = useState({
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        width: "100%",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        width: 2,
      },
      title: {
        text: "Pulse DCIR Curve",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      legend: {
        show: true,
        // position: "right",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // showForSingleSeries: true,
        // showForNullSeries: true,
        // showForZeroSeries: true,
      },
      xaxis: {
        tickAmount: 10,
        title: {
          text: "Time (Min.)",
        },
        decimalsInFloat: 1,
      },
      yaxis: [
        {
          title: {
            text: "Voltage (V)",
          },
          decimalsInFloat: 1,
        },
        {
          opposite: true,
          title: {
            text: "Current (A)",
            style: {
              color: "#21b017",
            },
          },
          decimalsInFloat: 1,
        },
      ],
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },

      tooltip: {
        enabled: true,
      },
      colors: ["#000", "#21b017"],
    },

    series: [
      // {
      //   name: "Voltage",
      //   data: VoltageData(voltage),
      //   type: "line",
      //   yAxisIndex: 0,
      // },
      // {
      //   name: "Current",
      //   data: VoltageData2(voltage),
      //   type: "line",
      //   yAxisIndex: 1,
      // },
      {
        name: "Voltage",
        data: [],
        type: "line",
        yAxisIndex: 0,
      },
      {
        name: "Current",
        data: [],
        type: "line",
        yAxisIndex: 1,
      },
    ],
  });

  const [state3, setState3] = useState({
    options: {
      legend: {
        show: true,
        // position: "left",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // horizontalAlign: "center",
        // border: "1px solid #ccc",
        // left: "80px",
        // background: "white",
        // bottom: "80px",
        labels: {
          text: "CC Current @ c/3",
        },
      },
      chart: {
        type: "line",
        toolbar: {
          show: false, // Hide toolbar
        },
        width: "100%",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      markers: {
        size: 3,
        colors: ["#fc0303", "#0324fc"],
        strokeColors: "#fff",
        strokeWidth: 2,
        shape: ["square", "circle"],
        hover: {
          size: 5,
        },
      },
      stroke: {
        width: 2,
        dashArray: [2, 0],
      },
      title: {
        text: "Nyquist Plot",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      xaxis: {
        tickAmount: 3,
        min: 2,
        max: 5,
        title: {
          text: "Real Z (mΩ)",
        },
        decimalsInFloat: 1,
        labels: {
          formatter: function (value: any) {
            return value.toFixed(1);
          },
        },
      },
      yaxis: {
        tickAmount: 10,
        title: {
          text: "imaginary Z (mΩ)",
        },
        decimalsInFloat: 1,
        // min: 0,
        // max: 5,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
      colors: ["#fc0303", "#0324fc"],
    },
    series: [
      {
        name: "Actual Data",
        data: nyquist(graphData).redData,
      },
      {
        name: "Fitted Data",
        data: nyquist(graphData).blueData,
      },
      // {
      //   name: "Actual Data",
      //   data: [],
      // },
      // {
      //   name: "Fitted Data",
      //   data: [],
      // },
    ],
  });
  // console.log("nyquist(voltage).redData: ", nyquist(graphData).redData);
  // console.log("nyquist(voltage).blueData: ", nyquist(graphData).blueData);
  const [state4, setState4] = useState({
    options: {
      // title: {
      //   text: "dQ/dV Curve with Identified Trough and Peak",
      //   align: 'center',
      //   margin: 2,
      //   offsetX: 0,
      //   offsetY: 0,
      //   floating: true,
      //   style: {
      //     fontSize: '10px',
      //     fontWeight: '500',
      //     color: '#000'
      //   },
      // },
      annotations: {
        points: [
          {
            x: findPeaks(DVA).highestPeak.x,
            y: findPeaks(DVA).highestPeak.y,
            marker: {
              size: 3,
              fillColor: "red",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              offsetY: 0,
              style: {
                color: "#000",
                border: "none",
              },

              text: "Last Peak",
            },
          },
          {
            x: findPeaks(DVA).lowestPeak.x,
            y: findPeaks(DVA).lowestPeak.y,
            marker: {
              size: 3,
              fillColor: "red",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              offsetY: 0,
              style: {
                color: "#000",
              },
              text: "First Trough",
            },
          },
        ],
      },
      legend: {
        show: true,
        // position: "right",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // showForSingleSeries: true,
        // showForNullSeries: true,
        // showForZeroSeries: true,
      },
      chart: {
        type: "line",
        toolbar: {
          show: false, // Hide toolbar
        },
        width: "100%",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        width: 2,
        curve: "straight",
      },
      title: {
        text: " dQ/dV vs Capacity Curve",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      xaxis: {
        tickAmount: 8,
        title: {
          text: "Capacity(Ah)",
        },
        decimalsInFloat: 1,
      },
      yaxis: {
        title: {
          text: "dQ/dV",
        },
        decimalsInFloat: 1,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
      colors: ["#0324fc", "#ff1100"],
    },
    series: [
      // {
      //   name: "Smoothed dQ/dV",
      //   data: DvaData(DVA),
      // },
      {
        name: "Smoothed dQ/dV",
        data: [],
      },
      {
        name: "Identified Points",
        data: [],
      },
    ],
  });

  const [state1, setState1] = useState({
    options: {
      title: {
        text: "Voltage vs Capacity for the Second Cycle",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      legend: {
        show: true,
        // position: "left",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // horizontalAlign: "center",
        // border: "1px solid #ccc",
        // left: "80px",
        // background: "white",
        // bottom: "80px",
        labels: {
          text: "CC Current @ c/3",
        },
      },
      chart: {
        type: "line",
        toolbar: {
          show: false, // Hide toolbar
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        width: "100%",
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Line Chart Example",
      // },
      xaxis: {
        categories: [],
        title: {
          text: "Capacity (Ah)",
          fontSize: "10px",
        },
        tickAmount: 10,
        decimalsInFloat: 1,
      },
      yaxis: {
        title: {
          text: "Voltage (V)",
          fontSize: "10px",
        },
        labels: {
          formatter: function (value: any) {
            return value + 0.5;
          },
        },
        decimalsInFloat: 1,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#fc0303", "#0324fc"],
      tooltip: {
        enabled: true,
      },
    },

    series: [
      // {
      //   name: "Charge",
      //   data: reversedData(grapOne),
      // },
      // {
      //   name: "Discharge",
      //   data: reversedData(grapTwo),
      // },
      {
        name: "Charge",
        data: [],
      },
      {
        name: "Discharge",
        data: [],
      },
    ],
  });

  // console.log("state1", state1);

  const [state, setState] = useState({
    series: [
      {
        data: [21, 22],
        name: "value",
      },
    ],
    options: {
      title: {
        text: "Coulombic Efficiency",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        width: "100%",
      },
      colors: ["#059911", "#0324fc"],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        decimalsInFloat: 0,
        categories: ["Cycle 2", "Cycle 3"],
        tickPlacement: "on",
      },

      yaxis: {
        decimalsInFloat: 0,
        title: {
          // text: "Value",
        },
      },
    },
  });

  const handleModuleIdData = () => {
    setLoading(true);
    setPdf("");
    setHisSkip(false);
    const body: any = { packId: moduleId };
    getPackIdDataReport(body)
      .then((res) => {
        console.log("%c Line:151 🍺 res", "color:#42b983", res);
        if (res?.success) {
          setState({
            ...state,
            series: [
              {
                name: "Value",
                data: [
                  res?.data?.data?.electrical_inspection_six?.charge_capacities
                    ?.length > 0 &&
                    res?.data?.data?.electrical_inspection_six
                      ?.charge_capacities[0],
                  res?.data?.data?.electrical_inspection_six?.charge_capacities
                    ?.length > 0 &&
                    res?.data?.data?.electrical_inspection_six
                      ?.charge_capacities[1],
                ] as any,
              },
            ],
          });
          const step1: number = Math.ceil(
            res?.data?.data?.electrical_inspection_six?.cc_dchg_curve?.length /
              40
          );
          const graph1: any = [];
          for (let i = 0; i < res?.data?.data?.data?.length; i += 100) {
            graph1.push(+res?.data?.data?.data[i]?.[0]);
          }
          // console.log("graph1: ", graph1);
          const step2: number = Math.ceil(
            res?.data?.data?.electrical_inspection_six?.cccv_chg_curve?.length /
              40
          );
          const graph2: any = [];
          for (let i = 0; i < res?.data?.data?.data?.length; i += 100) {
            graph2.push(+res?.data?.data?.data[i]?.[1]);
          }
          // console.log("graph2: ", graph2);
          const reversed1: any = reversedData(graph1);
          const reversed2: any = reversedData(graph2);

          setState1({
            ...state1,
            options: {
              ...state1.options,
              xaxis: {
                ...state1.options.xaxis,
                tickAmount: 50,
              },
            },
            series: [
              {
                name: "Voltage",
                data: graph1,
              },
              {
                name: "Capacity",
                data: graph2,
              },
            ],
          });
          const voltageValues: any = VoltageData(
            res.data.data?.electrical_inspection_four?.voltage_vs_time_curve &&
              res.data.data?.electrical_inspection_four?.voltage_vs_time_curve
          );
          const currentValues: any = VoltageData2(
            res.data.data?.electrical_inspection_four?.voltage_vs_time_curve &&
              res.data.data?.electrical_inspection_four?.voltage_vs_time_curve
          );
          setState2({
            ...state2,
            series: [
              {
                name: "Voltage",
                data: voltageValues,
                type: "line",
                yAxisIndex: 0,
              },
              {
                name: "Current",
                data: currentValues,
                type: "line",
                yAxisIndex: 1,
              },
            ],
          });
          const step3: number = Math.ceil(
            res?.data?.data?.electrical_inspection_five?.graph_data?.length / 20
          );
          const graph3: any = [];
          for (
            let i = 0;
            i < res?.data?.data?.electrical_inspection_five?.graph_data?.length;
            i += step3
          ) {
            graph3.push(
              res?.data?.data?.electrical_inspection_five?.graph_data[i]
            );
          }

          const redData: any = nyquist(
            res?.data?.data?.electrical_inspection_five?.graph_data &&
              res?.data?.data?.electrical_inspection_five?.graph_data
          ).redData;
          const blueData: any = nyquist(
            res?.data?.data?.electrical_inspection_five?.graph_data &&
              res?.data?.data?.electrical_inspection_five?.graph_data
          ).blueData;
          setState3({
            ...state3,
            series: [
              {
                name: "Actual Data",
                data: redData,
              },
              {
                name: "Fitted Data",
                data: blueData,
              },
            ],
            options: {
              ...state3.options,
              xaxis: {
                ...state3.options.xaxis,
                min: Math.min(
                  ...redData.map((d: any) => d[0] - 0.1),
                  ...blueData.map((d: any) => d[0] - 0.1)
                ),
                max: Math.max(
                  ...redData.map((d: any) => d[0] + 0.1),
                  ...blueData.map((d: any) => d[0] + 0.1)
                ),
              },
            },
          });
          const graph4: any = [];
          for (
            let i = 0;
            i <
            res?.data?.data?.electrical_inspection_six
              ?.capacity_vs_smoothed_dQdV_curve?.length;
            i += 10
          ) {
            graph4.push(
              res?.data?.data?.electrical_inspection_six
                ?.capacity_vs_smoothed_dQdV_curve[i]
            );
          }

          // console.log("graph3: ", graph3);
          setState4({
            ...state4,
            options: {
              ...state4.options,
              annotations: {
                points: [
                  {
                    x: findPeaks(graph4).highestPeak.x,
                    y: findPeaks(graph4).highestPeak.y,
                    marker: {
                      size: 3,
                      fillColor: "red",
                      strokeColor: "red",
                      radius: 2,
                      cssClass: "apexcharts-custom-class",
                    },
                    label: {
                      offsetY: 0,
                      style: {
                        color: "#000",
                        border: "none",
                      },

                      text: "Last Peak",
                    },
                  },
                  {
                    x: findPeaks(graph4).lowestPeak.x,
                    y: findPeaks(graph4).lowestPeak.y,
                    marker: {
                      size: 3,
                      fillColor: "red",
                      strokeColor: "red",
                      radius: 2,
                      cssClass: "apexcharts-custom-class",
                    },
                    label: {
                      offsetY: 0,
                      style: {
                        color: "#000",
                      },

                      text: "First Trough",
                    },
                  },
                ],
              },
            },
            series: [
              {
                name: "Smooth dQ/dV",
                data: graph4,
              },
              {
                name: "Identified Points",
                data: [],
              },
            ],
          });
          setPdf(res?.data?.data);
          if (
            typeof res?.data?.data?.electrical_inspection_four === "object" &&
            res?.data?.data?.electrical_inspection_four !== null &&
            Object.keys(res?.data?.data?.electrical_inspection_four)?.length ===
              0
          ) {
            setHisSkip(true);
          } else {
            setHisSkip(false);
          }
          downloadPdf();
          // exportMultipleChartsToPdf(res?.data?.data?.module_id);
          // window.close();
        }
        setLoading(false);
        // setBatteryModuleDropdown(res?.data?.data)
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  React.useEffect(() => {
    if (pdf) {
      exportMultipleChartsToPdf(pdf?.packId);
      // const timer = setTimeout(() => {
      //   window.close();
      // }, 15000);

      // return () => clearTimeout(timer);
    }
  }, [pdf]);

  React.useEffect(() => {
    if (moduleId) {
      handleModuleIdData();
    }
  }, [moduleId]);

  React.useEffect(() => {
    if (hisMesuments.length > 0 && loading) {
      const promiseArray = [];
      for (let i = 0; i < hisMesuments.length; i++) {
        promiseArray.push(
          ApexCharts.exec(`chart_${i}`, "dataURI").then(({ imgURI }: any) => {
            return { type: hisMesuments[i].type, url: imgURI };
          })
        );
      }

      Promise.all(promiseArray).then((res) => {
        const new_Data: any = {};
        for (let i = 0; i < res.length; i++) {
          new_Data[res[i].type] = res[i].url;
        }
        setHisDataURI(new_Data);
        setOpen(true);
        setLoading(false);
      });
    }
  }, [hisMesuments]);

  const getTargetElement = () => document.getElementById("container");
  const options: Options = {
    filename: `${pdf?.packId}.pdf`,
    page: {
      // default is 'A4'
      format: "letter",
    },
    canvas: {},
  };
  const downloadPdf = () => {
    generatePDF(getTargetElement, options);
    // captureAndGeneratePDF()
  };

  const captureAndGeneratePDF = async () => {
    const pdf: any = new jsPDF();
    const options = {
      width: page1.current.offsetWidth, // Width of div
      height: page1.current.offsetHeight, // Height of div
      scale: 0.73, // No scaling
      useCORS: true,
    };
    // Allow cross-origin images };

    // Capture screenshots of div sections
    const div1Image = await html2canvas(page1.current, options);
    const div2Image = await html2canvas(page2.current, options);
    const div3Image = await html2canvas(page3.current, options);
    const div4Image = await html2canvas(page4.current, options);

    // Add div section screenshots to PDF
    pdf.addImage(div1Image.toDataURL(), "PNG", 10, 10);
    pdf.addPage();
    pdf.addImage(div2Image.toDataURL(), "PNG", 10, 10);
    pdf.addPage();
    pdf.addImage(div3Image.toDataURL(), "PNG", 10, 10);
    pdf.addPage();
    pdf.addImage(div4Image.toDataURL(), "PNG", 10, 10);

    // Save or display the PDF
    pdf.save(moduleId + ".pdf");
  };
  // useEffect(() => {
  //   if (pdf) {
  //     console.log("%c Line:543 🥔 graph", "color:#4fff4B", pdf)
  //     generatePDF(getTargetElement, options)
  //   }
  // }, [pdf])

  function createData(
    name: any,
    calories: any,
    fat: any,
    carbs: any,
    protein: any
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  const isOemSerialId = (obj: any) => {
    if (pdf) {
      if (obj?.oem_serial_id) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
      <Favicon url={loading ? animatedFavicon : favicon} />

      {pdf ? (
        <div
          id="container"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            maxWidth: "1024px",
          }}
        >
          <div style={{ width: "100%" }}>
            <Grid container justifyContent={"center"}>
              <div className="custom-chart" style={{ paddingTop: "15px" }}>
                <Header />
                <div className="Inner_padding">
                  <Grid
                    item
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      flexDirection: "column",
                    }}
                  >
                    <h2
                      style={{
                        width: "80%",
                        fontSize: "35px",
                        textAlign: "center",
                        fontWeight: "700",
                        color: "#3498d2",
                        marginInline: "auto",
                      }}
                    >
                      Pack Id: {pdf?.packId}
                    </h2>
                  </Grid>
                  <div
                    className="tableFlex"
                    style={{ gap: "10px", display: "none" }}
                  >
                    <TableContainer
                      style={{
                        width: "50%",
                        borderRadius: "0px",
                        boxShadow: "none",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead
                          style={{
                            backgroundColor: "#3498d2",
                            width: "100%",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{
                                color: "white",
                                width: "100%",
                                border: "2px solid #3498d2",
                              }}
                            >
                              Rated Specifications
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              component="th"
                              scope="row"
                            >
                              Parameter
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Value
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Units
                            </TableCell>
                          </TableRow>{" "}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              Rated Capacity @C/3, 25°C
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.oem === "Caterpillar"
                                ? "105"
                                : pdf?.oem === "Jaguar Land Rover"
                                ? "232"
                                : pdf?.oem === "Nissan" &&
                                  pdf?.oem_model === "Gen 4"
                                ? "112"
                                : pdf?.oem === "Nissan" &&
                                  pdf?.oem_model === "Gen 2"
                                ? "66"
                                : "84"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              Ah
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              Voltage: Maximum
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.oem === "Caterpillar" ? "58.8" : "8.4"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              V
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              Voltage: Nominal
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.oem === "Caterpillar" ? "50" : "7.2"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              V
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              Voltage: Minimum
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.oem === "Caterpillar" ? "42" : "5"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              V
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer
                      style={{
                        width: "50%",
                        boxShadow: "none",
                        borderRadius: "0px",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead
                          style={{
                            backgroundColor: "#3498d2",
                            color: "white",
                            boxShadow: "none",
                            width: "100%",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{
                                color: "white",
                                border: "2px solid #3498d2",
                              }}
                            >
                              Estimated Specifications
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                                height: "56px",
                              }}
                              component="th"
                              scope="row"
                            >
                              Parameter
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Value
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Units
                            </TableCell>
                          </TableRow>{" "}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                height: "56px",
                              }}
                              component="th"
                              scope="row"
                            >
                              Observed Capacity @C/3, 25°C
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_six
                                ?.estimated_specification?.length > 0
                                ? Number(
                                    pdf?.electrical_inspection_six?.estimated_specification[0][
                                      "Observed Capacity @C/3, 25°C"
                                    ]?.split(" ")[0]
                                  ).toFixed(1)
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              Ah
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            {" "}
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                height: "56px",
                              }}
                              component="th"
                              scope="row"
                            >
                              Estimated SOH
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_six
                                ?.estimated_specification?.length > 0
                                ? pdf?.electrical_inspection_six?.estimated_specification[0][
                                    "Estimated SOH"
                                  ]?.split(".")[0] +
                                  "." +
                                  pdf?.electrical_inspection_six?.estimated_specification[0][
                                    "Estimated SOH"
                                  ]
                                    ?.split(".")[1]
                                    ?.slice(0, 1)
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              %
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="imageGrid-QIS">
                    {/* <div className="gridItems">
                      {pdf?.images?.top_image?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.images?.top_image} alt="hs1" />
                      ) : (
                        <img
                          src={pdf?.oem_model === "Gen 4" ? top : top2}
                          alt="hs1"
                        />
                      )}
                      <p>Top View</p>
                    </div>
                    <div className="gridItems">
                      {pdf?.images?.bottom_image?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.images?.bottom_image} alt="hs1" />
                      ) : (
                        <img
                          src={pdf?.oem_model === "Gen 4" ? bottom : bottom2}
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Bottom View</p>
                    </div>
                    <div className="gridItems">
                      {pdf?.images?.left_image?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.images?.left_image} alt="hs1" />
                      ) : (
                        <img
                          src={pdf?.oem_model === "Gen 4" ? left : left2}
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Left View</p>
                    </div>
                    <div className="gridItems">
                      {pdf?.images?.right_image?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.images?.right_image} alt="hs1" />
                      ) : (
                        <img
                          src={pdf?.oem_model === "Gen 4" ? right : right2}
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Right View</p>
                    </div>
                    <div className="gridItems">
                      {pdf?.images?.front_image?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.images?.front_image} alt="hs1" />
                      ) : (
                        <img
                          src={pdf?.oem_model === "Gen 4" ? front : front2}
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Front View</p>
                    </div>
                    <div className="gridItems">
                      {pdf?.images?.back_image?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.images?.back_image} alt="hs1" />
                      ) : (
                        <img
                          src={pdf?.oem_model === "Gen 4" ? back : back2}
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Back View</p>
                    </div> */}
                    <div className="gridItems QIS-img">
                      {pdf?.photoStationTop?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.photoStationTop} alt="hs1" />
                      ) : (
                        <img
                          src={
                            pdf?.photoStationTop
                              ? pdf?.photoStationTop
                              : bottom2
                          }
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Pack Top Image </p>
                    </div>
                    <div className="gridItems QIS-img">
                      {pdf?.photoStationLeft?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.photoStationRight} alt="hs1" />
                      ) : (
                        <img
                          src={
                            pdf?.photoStationRight
                              ? pdf?.photoStationRight
                              : bottom2
                          }
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Pack Samsar Label</p>
                    </div>
                    <div className="gridItems QIS-img">
                      {pdf?.photoStationRight?.startsWith(
                        "data:image/jpeg;base64"
                      ) ? (
                        <img src={pdf?.photoStationLeft} alt="hs1" />
                      ) : (
                        <img
                          src={
                            pdf?.photoStationLeft
                              ? pdf?.photoStationLeft
                              : bottom2
                          }
                          alt="hs1"
                        />
                      )}{" "}
                      <p>Pack Warning Label</p>
                    </div>
                  </div>
                  <div className="page_number">
                    <span>1</span>
                  </div>
                </div>
              </div>

              <div className="custom-chart" style={{ paddingTop: "15px" }}>
                <Header />

                <div className="Inner_padding">
                  <div className="Energy">
                    <h1>Energy/Capacity</h1>
                  </div>
                  <div className="tableFlex2" style={{ gap: "10px" }}>
                    {/* <img src={energy} alt="Energy/Capacity" /> */}
                    <div className="legend1" style={{ width: "100%" }}>
                      <ReactApexChart
                        options={state1.options as any}
                        series={state1.series as any}
                        type="line"
                        height={350}
                      />
                    </div>

                    <div
                      className="Cyclechart"
                      style={{ width: "30%", display: "none" }}
                    >
                      <ReactApexChart
                        options={state.options as any}
                        series={state.series as any}
                        type="bar"
                        height={350}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <TableContainer
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                        boxShadow: "none",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#3498d2" }}>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{
                                color: "white",
                                border: "2px solid #3498d2",
                                fontSize: "15px",
                              }}
                            >
                              Health Indicator
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              component="th"
                              scope="row"
                            >
                              Parameter
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Value
                            </TableCell>
                          </TableRow>{" "}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              IoT Connection
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              <Checkbox readOnly={true} checked={true} />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              Heater Validation
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              <Checkbox readOnly={true} checked={true} />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              BMS Configured
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              <Checkbox readOnly={true} checked={true} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <TableContainer
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                        boxShadow: "none",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#3498d2" }}>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{
                                color: "white",
                                border: "2px solid #3498d2",
                                fontSize: "15px",
                              }}
                            >
                              Module Data
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              component="th"
                              scope="row"
                            >
                              Module
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Operator name
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Completed date
                            </TableCell>
                          </TableRow>{" "}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              Module Inspection
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.moduleInspectionCompletedBy &&
                                pdf?.moduleInspectionCompletedBy}{" "}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.moduleInspectionDate &&
                                moment(pdf?.moduleInspectionDate).format(
                                  "MM-DD-YYYY  h:mm A"
                                )}{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              QIS 1 : Assembly 1
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis1CompletedBy && pdf?.qis1CompletedBy}{" "}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis1Date &&
                                moment(pdf?.qis1Date).format(
                                  "MM-DD-YYYY  h:mm A"
                                )}{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              QIS 2 : Calibration
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis2CompletedBy && pdf?.qis2CompletedBy}{" "}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis2Date &&
                                moment(pdf?.qis2Date).format(
                                  "MM-DD-YYYY  h:mm A"
                                )}{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              QIS 3 : Assembly 2
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis3CompletedBy && pdf?.qis3CompletedBy}{" "}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis3Date &&
                                moment(pdf?.qis3Date).format(
                                  "MM-DD-YYYY  h:mm A"
                                )}{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              QIS 4 : Cycling
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis4CompletedBy && pdf?.qis4CompletedBy}{" "}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.qis4Date &&
                                moment(pdf?.qis4Date).format(
                                  "MM-DD-YYYY  h:mm A"
                                )}{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              component="th"
                              scope="row"
                            >
                              Pack Packaging
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.packPackagingCompletedBy &&
                                pdf?.packPackagingCompletedBy}{" "}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontSize: "12px",
                              }}
                              align="right"
                            >
                              {pdf?.packPackagingDate &&
                                moment(pdf?.packPackagingDate).format(
                                  "MM-DD-YYYY  h:mm A"
                                )}{" "}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {/* <div className="Energy" style={{ display: "none" }}>
                    <h1>Power/Rate Capability</h1>
                  </div> */}
                  {/* <div className="tableFlex2" style={{ display: "none" }}>
                    <div style={{ width: "100%" }}>
                      <ReactApexChart
                        options={state2.options as any}
                        series={state2.series as any}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div> */}
                  {/* <div style={{ marginTop: "50px", display: "none" }}>
                    <TableContainer
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                        boxShadow: "none",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#3498d2" }}>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{
                                color: "white",
                                border: "2px solid #3498d2",
                              }}
                            >
                              Health Indicator
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              component="th"
                              scope="row"
                            >
                              Parameter
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Value
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Units
                            </TableCell>
                          </TableRow>{" "}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              DCIR @ 15A (V)
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {(
                                Number(
                                  pdf?.electrical_inspection_four?.dcir_values
                                    ?.length > 0
                                    ? pdf?.electrical_inspection_four
                                        ?.dcir_values[0]
                                    : 0
                                ) * 1000
                              )?.toFixed(2)}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              mΩ
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              DCIR @ 30A (V)
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {(
                                Number(
                                  pdf?.electrical_inspection_four?.dcir_values
                                    ?.length > 1 &&
                                    pdf?.electrical_inspection_four
                                      ?.dcir_values[1]
                                ) * 1000
                              )?.toFixed(2)}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              mΩ
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              DCIR @ 60A (V)
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {(
                                Number(
                                  pdf?.electrical_inspection_four?.dcir_values
                                    ?.length > 2 &&
                                    pdf?.electrical_inspection_four
                                      ?.dcir_values[2]
                                ) * 1000
                              ).toFixed(2)}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              mΩ
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div> */}
                  <div className="page_number">
                    <span>2</span>
                  </div>
                </div>
              </div>

              {/* <div
                className="custom-chart"
                style={{ paddingTop: "15px", display: "none" }}
              >
                <Header />
                <div className="Inner_padding">
                  <div className="Energy" style={{ marginTop: "10px" }}>
                    <h1>Electrochemical Impedance Spectroscopy(EIS)</h1>
                  </div>
                  <div className="tableFlex2">
                    <div className="Nyquist legend1">
                      <ReactApexChart
                        options={state3.options as any}
                        series={state3.series as any}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <TableContainer
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                        boxShadow: "none",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#3498d2" }}>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{
                                color: "white",
                                border: "2px solid #3498d2",
                              }}
                            >
                              Health Indicator
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              component="th"
                              scope="row"
                            >
                              Parameter
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Value
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Units
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[0]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[0]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[0]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[1]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[1]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[1]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[2]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[2]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[2]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[3]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[3]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[3]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[4]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[4]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[4]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[5]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[5]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[5]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[6]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[6]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[6]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[7]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[7]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[7]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[8]?.[0]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[8]?.[1]
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              {pdf?.electrical_inspection_five?.eis_parameters
                                ? pdf?.electrical_inspection_five
                                    ?.eis_parameters[8]?.[2]
                                : "-"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="Energy">
                    <h1>Differential Voltage Analysis (DVA)</h1>
                  </div>
                  <div className="tableFlex2" style={{ marginTop: "20px" }}>
                    <div className="DVA legend2">
                      <ReactApexChart
                        options={state4.options as any}
                        series={state4.series as any}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div>
                  <div className="page_number">
                    <span>3</span>
                  </div>
                </div>
              </div> */}

              <div
                className="custom-chart"
                ref={page4}
                style={{ paddingTop: "15px" }}
              >
                <Header />
                <div className="Inner_padding">
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      display: "none",
                    }}
                  >
                    <TableContainer
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                        boxShadow: "none",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#3498d2" }}>
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              style={{
                                color: "white",
                                border: "2px solid #3498d2",
                                textAlign: "right",
                              }}
                            >
                              Health Indicator
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              component="th"
                              scope="row"
                            >
                              Description
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              component="th"
                              scope="row"
                            >
                              Parameter
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Value
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                              }}
                              align="right"
                            >
                              Units
                            </TableCell>
                          </TableRow>{" "}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              2nd Cycle 1st Trough
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "0px",
                              }}
                              component="th"
                              scope="row"
                            >
                              <p
                                style={{
                                  width: "100%",
                                  borderBottom: "2px solid #3498d2",
                                }}
                              >
                                dQdV
                              </p>
                              <p>Capacity</p>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "0px",
                              }}
                              align="right"
                            >
                              <p
                                style={{
                                  width: "100%",
                                  borderBottom: "2px solid #3498d2",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                {" "}
                                {`${
                                  pdf?.electrical_inspection_six
                                    ?.health_parameters?.length > 0 &&
                                  pdf?.electrical_inspection_six?.health_parameters[0][
                                    `(dQ/dV,Ah) 2nd Cycle 1st Trough`
                                  ]?.dQdV?.toFixed(1)
                                }`}
                              </p>
                              <p>{`${
                                pdf?.electrical_inspection_six
                                  ?.health_parameters?.length > 0 &&
                                pdf?.electrical_inspection_six?.health_parameters[0][
                                  `(dQ/dV,Ah) 2nd Cycle 1st Trough`
                                ]?.Capacity?.toFixed(1)
                              }`}</p>
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              (dQ/dV,Ah)
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              component="th"
                              scope="row"
                            >
                              2nd Cycle Last Peak
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "0px",
                              }}
                              component="th"
                              scope="row"
                            >
                              <p
                                style={{
                                  width: "100%",
                                  borderBottom: "2px solid #3498d2",
                                }}
                              >
                                dQdV
                              </p>
                              <p>Capacity</p>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "0px",
                              }}
                              align="right"
                            >
                              <p
                                style={{
                                  width: "100%",
                                  borderBottom: "2px solid #3498d2",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                {`${
                                  pdf?.electrical_inspection_six
                                    ?.health_parameters?.length > 0 &&
                                  pdf?.electrical_inspection_six?.health_parameters[0][
                                    `(dQ/dV,Ah) 2nd Cycle last Peak`
                                  ]?.dQdV?.toFixed(1)
                                }`}
                                <br />
                              </p>

                              <p>{`${
                                pdf?.electrical_inspection_six
                                  ?.health_parameters?.length > 0 &&
                                pdf?.electrical_inspection_six?.health_parameters[0][
                                  `(dQ/dV,Ah) 2nd Cycle last Peak`
                                ]?.Capacity?.toFixed(1)
                              }`}</p>
                            </TableCell>
                            <TableCell
                              style={{ border: "2px solid #3498d2" }}
                              align="right"
                            >
                              (dQ/dV,Ah)
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="Energy2" style={{ marginTop: "20px" }}>
                    <h1>Terms and Conditions</h1>
                  </div>
                  <div className="paragraph">
                    <p>
                      Samsar Resources expressly disclaims any responsibility
                      for all damage, injury, harm, cost, expense, or liability
                      arising out of or related, in whole or in part, to the use
                      or misuse of the battery which is subject to this state of
                      health.
                    </p>
                    <br />
                    <p>
                      To the fullest extent allowable by applicable law, this
                      state of health is provided without warranty of any kind
                      whether express, implied, or statutory including, but not
                      limited to, warranties of merchantability and warranty of
                      fitness for a particular purpose.
                    </p>
                    <br />
                    <p>
                      Samsar Resources is not and will not be liable under any
                      legal theory including but not limited to actions sounding
                      warranty, contract, strict liability, tort, negligence,
                      and/or misrepresentation for any and all damage, injury,
                      harm, cost, expense, or liability arising out of or
                      related, in whole or in part, to the use or misuse of the
                      battery which is subject to this state of health.
                    </p>
                    <br />
                    <p>
                      Samsar Resources is not and will not be liable for damage
                      to property, loss of business, loss of income, special
                      damages, incidental damages, consequential damages,
                      punitive damages, or exemplary damages, whether
                      foreseeable or unforeseeable.
                    </p>
                    <br />
                    <p>
                      In no event will Samsar Resources' liability exceed the
                      value of the Li-Ion battery for which this statement of
                      health is issued.
                    </p>
                    <br />
                    <p>
                      Important: Please read and follow all safety warnings and
                      advisories issued by the manufacturer of the battery for
                      which this health certification is issued. Contact the
                      manufacturer to obtain all such safety warnings and
                      advisories.
                    </p>
                  </div>

                  <div className="page_number4">
                    <span>3</span>
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#212121",
                width: "30%",
                cursor: "default",
              }}
            >
              <span className="report_loader"></span>Generating Report
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
