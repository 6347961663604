import React from "react";
import { Field } from "../../types";
import { PublishReport } from "./ReportGenerate";
import { PublishPassport } from "./PassportGenerate";
import { QisReport } from "./QisReport";

const CustomAction = ({ data, status }: { data: Field; status: boolean }) => {
  return (
    <>
      {data.action === "publish_report" && (
        <PublishReport data={data} status={status} />
      )}
      {data.action === "publish_passport" && (
        <PublishPassport data={data} status={status} />
      )}
      {data.action === "QIS_report" && (
        <QisReport data={data} status={status} />
      )}
    </>
  );
};

export default React.memo(CustomAction);
