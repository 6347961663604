import List from "@mui/material/List";
import { Typography } from "@mui/material";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { fieldmap } from "../fieldmap";
import { useAppSelector } from "../../../store/rtctype";
import UploadImage from "./UploadImage";
import { useEffect, useRef } from "react";

export const FormDetails = ({ step }: { step: string }) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const formData = useAppSelector((state: any) => state.inspection).formData;

  const keyToIndex: Record<string, number> = {
    right_image: 5,
    left_image: 4,
    back_image: 3,
    front_image: 2,
    bottom_image: 1,
    top_image: 0,
  };
  useEffect(() => {
    if (swiperRef?.current && formData["step_18"]) {
      const stepData = formData["step_18"];

      const foundKey = Object.keys(keyToIndex).find((key) =>
        Object.prototype.hasOwnProperty.call(stepData, key)
      );

      const index = foundKey !== undefined ? keyToIndex[foundKey] : -1;

      if (index !== -1) {
        swiperRef?.current?.slideTo(index);
      }
    }
  }, [formData["step_18"]]);

  return (
    <div className="sm-stepForm-wrap">
      {fieldmap[step]?.view_media && (
        <div className="sm-colm-4">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            loop={true}
            className="mySwiper "
          >
            {fieldmap[step].view_media?.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-img">
                    <img
                      src={require(`../../../assets/images/${image.src}`)}
                      alt={image.name}
                    />
                    <p>{image.name}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
      {fieldmap[step]?.upload_media && (
        <div className="sm-colm-4 upload_limi">
          <div className="sm-commen-box sm-img-box">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              loop={true}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {fieldmap[step].upload_media?.map((media, index) => {
                return (
                  <SwiperSlide key={index}>
                    <UploadImage
                      field={media}
                      step={step}
                      value={formData?.[step]?.[media.key] || ""}
                      key={index}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      )}

      {fieldmap[step]?.form_content && (
        <div className="sm-colm-4">
          <div className="sm-commen-box sm-numWeight-box">
            <nav className="sm-list" aria-label="secondary mailbox folders">
              <List>
                {fieldmap[step].form_content?.map((field, index) => {
                  return (
                    <ListItem disablePadding key={index}>
                      <ListItemButton>
                        <ListItemText primary={field.name} />
                        <Typography variant="body2">
                          {formData[step][field.key]
                            ? formData[step][field.key]
                            : "NA"}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};
