import React, { useState, memo } from "react";
import { Button, FormControl, InputLabel, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  handleFieldData,
  handlePackId,
} from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { validationHandle } from "../fieldmap";
import { toast } from "react-toastify";

const InputField = ({
  data,
  step,
  value,
  status,
  isView,
}: {
  data: Field;
  step: string;
  value: string;
  status: boolean;
  isView?: boolean;
}) => {
  const dispatch = useDispatch();
  const reset = useAppSelector((state: any) => state.inspection).toggle;
  const [fieldValue, setFieldValue] = useState<string>(value);
  const [isError, setIsError] = useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = useState<string>("");

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset, value]);

  const handleSave = () => {
    const patternForModule = /^[A-Za-z0-9]{10}-[0-9]{3}$/;
    if (
      data?.key === "attached_module_id" &&
      !patternForModule?.test(fieldValue)
    ) {
      toast.error("Plaese enter valid Module ID");
      return true;
    }
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
  };

  const handleInputChange = (event: any) => {
    if (data.validate) {
      const { isError, infoDetails } = validationHandle(
        data.validate,
        event.target.value.trim(),
        data
      );
      setIsError(isError);
      setFieldInfo(infoDetails);
    }
    setFieldValue(event.target.value);
  };

  const isButtonDisabled = () => {
    if (!fieldValue) {
      return true;
    }
    if (
      data?.key === "attached_module_id" &&
      fieldValue?.replace(/\s/g, "").length !== 14
    ) {
      return true;
    }
    if (step === "step_1") {
      if (
        data?.key === "identifier" &&
        fieldValue?.replace(/\s/g, "").length !== 11
      ) {
        return true;
      }
    } else {
      if (
        data?.key === "identifier" &&
        fieldValue?.replace(/\s/g, "").length !== 15
      ) {
        return true;
      }
    }

    return false;
  };
  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
        {data.validate && fieldValue && (
          <Tooltip
            className={`sm-tootip-true ${isError ? "sm-tootip-false" : ""}`}
            title={fieldInfo}
            arrow
          >
            <Button>
              {isError ? (
                <ExclamationCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )}
            </Button>
          </Tooltip>
        )}
      </InputLabel>
      <TextField
        disabled={!status || isView}
        type={
          data?.key == "number_of_units" ||
          data?.key == "voltageDifferenceOne" ||
          data?.key == "voltageDifferenceTwo" ||
          data?.key == "voltageDifferenceThree" ||
          data?.key == "voltageDifferenceFour"
            ? "number"
            : "text"
        }
        id={data.key}
        value={fieldValue || value}
        placeholder="Enter Value"
        onChange={handleInputChange}
      />
      <Button
        variant="contained"
        onClick={handleSave}
        disabled={isButtonDisabled()}
      >
        Save
      </Button>
    </FormControl>
  );
};

export default memo(InputField);
