import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select as MuiSelect,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import PDFContent from "../../../../components/PDFGen/PDFContent";
import { Field } from "../../types";
import ReactApexChart from "react-apexcharts";
import generatePDF, { Options } from "react-to-pdf";
import { chartOption } from "../../../../components/Chart/chartOption";
import {
  getBatteryMOdules,
  getModel,
  getOEM,
  getPassportList,
  getReportData,
  getReportModuleId,
} from "../../../../service/apiService";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReportPDF from "./Report/ReportPDF";
// import ReportPDF2 from "./Report2/ReportPDF2"

const graphArray: any = {
  his4: {
    category: "single",
    xAxis: "DataPoint",
    yAxis: "Voltage(V)",
  },
  his5: {
    category: "dual",
    xAxis: "Zreal (ohm)",
    yAxis: [" -Zimag (ohm)", " Fit1 - -Zimag (ohm)"],
  },
  his6: {
    category: "single",
    xAxis: "Capacity(Ah)",
    yAxis: "Voltage(V)",
  },
  his62: {
    category: "single",
    xAxis: "Capacity (Ah)",
    yAxis: "dQ/dV (Ah/V)",
  },
};

export const PublishPassport = ({
  data,
  status,
}: {
  data: Field;
  status: boolean;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [hisDataURI, setHisDataURI] = React.useState<any>(null);
  const [reportData, setReportData] = React.useState<any>(null);
  const [download, setDownload] = React.useState<any>(false);
  const [moduleId, setModuleId] = useState<any>("");
  const [batteryModuleDropdown, setBatteryModuleDropdown] = useState<any>([
    // { module_id: "121923-002-HTX-006" },
    // { module_id: "121923-002-HTX-001" },
    // { module_id: "121923-002-HTX-014" },
    // { module_id: "121923-002-HTX-005" },
    // { module_id: "121923-002-HTX-002" },
    // { module_id: "121923-002-HTX-003" },
    // { module_id: "121923-002-HTX-009" },
    // { module_id: "121923-002-HTX-013" },
    // { module_id: "121923-002-HTX-004" },
    // { module_id: "121923-002-HTX-008" },
    // { module_id: "121923-002-HTX-011" },
    // { module_id: "121923-002-HTX-015" },
    // { module_id: "020724-001-HSTX-001" },
    // { module_id: "020724-001-HSTX-002" },
    // { module_id: "020724-001-HSTX-003" },
    // { module_id: "020724-001-HSTX-004" },
    // { module_id: "121923-002-HTX-016" },
    // { module_id: "112423-002-HTX-009" },
    // { module_id: "112423-002-HTX-011" },
    // { module_id: "112423-002-HTX-010" },
    // { module_id: "112423-002-HTX-012" },
    // { module_id: "112423-002-HTX-003" },
    // { module_id: "112423-002-HTX-004" },
    // { module_id: "112423-002-HTX-007" },
    // { module_id: "112423-002-HTX-005" },
    // { module_id: "112423-002-HTX-006" },
    // { module_id: "112423-002-HTX-020" },
    // { module_id: "112423-002-HTX-016" },
    // { module_id: "112423-002-HTX-017" },
    // { module_id: "112423-002-HTX-008" },
    // { module_id: "112423-002-HTX-019" },
    // { module_id: "112423-002-HTX-001" },
    // { module_id: "112423-002-HTX-015" },
    // { module_id: "112423-002-HTX-014" },
    // { module_id: "112423-002-HTX-013" },
    // { module_id: "112423-002-HTX-018" },
    // { module_id: "112423-002-HTX-024" },
    // { module_id: "112423-002-HTX-025" },
    // { module_id: "112423-002-HTX-021" },
    // { module_id: "112423-002-HTX-022" },
  ]);

  const [OEM, setOEM] = React.useState<any>([]);
  const [model, setModel] = React.useState<any>([]);
  const [OEMChart, setOEMChart] = React.useState<any>({
    label: "All",
    value: "",
  });
  const [modelChart, setModelChart] = React.useState<any>({
    label: "All",
    value: "",
    oem_id: "",
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const getList = () => {
    getPassportList()
      .then((res: any) => {
        console.log("%c Line:138 🍉 any", "color:#42b983", res);
        if (res?.success) {
          setBatteryModuleDropdown(res?.data?.data);
        }
      })
      .catch((e: any) => {
        console.log("%c Line:140 🥃 e", "color:#7f2b82", e);
      });
  };

  React.useEffect(() => {
    getList();
  }, []);

  const handleDropdown = () => {
    getOEM()
      .then((res) => {
        if (res?.data) {
          const arr: any = [{ label: "All", value: "" }];
          res?.data?.data?.map((obj: any) => {
            arr.push({ label: obj?.name, value: obj?.code });
          });
          setOEM(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    getModel()
      .then((res) => {
        if (res?.data) {
          const arr: any = [{ label: "All", value: "", oem_id: "" }];

          res?.data?.data?.map((obj: any) => {
            arr.push({
              label: obj?.name,
              value: obj?.code,
              oem_id: obj?.oem,
            });
          });

          setModel(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleModuleId = () => {
    if (!startDate) {
      return;
    }
    if (!endDate) {
      return;
    }
    if (!OEMChart) {
      return;
    }
    if (!modelChart) {
      return;
    }
    const params: any = {
      startDate: moment(startDate)?.format("DD/MM/YYYY"),
      endDate: moment(endDate)?.format("DD/MM/YYYY"),
      oem: OEMChart?.value,
      model: modelChart?.value,
    };

    getReportModuleId(params)
      .then((res) => {
        console.log("%c Line:110 🍣 res", "color:#b03734", res);
        if (res?.success) {
          setBatteryModuleDropdown(res?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // React.useEffect(() => {
  //   handleModuleId();
  // }, [startDate, endDate, modelChart, OEMChart]);

  // React.useEffect(() => {
  //   const authToken = localStorage.getItem("accessToken") || "";
  //   handleDropdown();
  //   getBatteryMOdules(authToken).then((res) => {
  //     if (res.success) {
  //       setBatteryModule(res.data);
  //       if (res.data.length > 0) {
  //         setModuleId(res.data[0].identifier);
  //       }
  //     }
  //   });
  // }, []);

  // const handleContinue = () => {
  //   const authToken = localStorage.getItem("accessToken") || "";
  //   if (!hisMesuments.length || !reportData) {
  //     setLoading(true);

  //     if (!hisMesuments.length) {
  //       getModuleData(authToken);
  //       // handleModuleIdData()
  //     }
  //   } else {
  //     setOpen(true);
  //   }
  // };

  const handleChange = (event: SelectChangeEvent) => {
    setModuleId(event.target.value as string);
  };

  // React.useEffect(() => {
  //   if (hisMesuments.length > 0 && loading) {
  //     const promiseArray = [];
  //     for (let i = 0; i < hisMesuments.length; i++) {
  //       promiseArray.push(
  //         ApexCharts.exec(`chart_${i}`, "dataURI").then(({ imgURI }: any) => {
  //           return { type: hisMesuments[i].type, url: imgURI };
  //         })
  //       );
  //     }

  //     Promise.all(promiseArray).then((res) => {
  //       const new_Data: any = {};
  //       for (let i = 0; i < res.length; i++) {
  //         new_Data[res[i].type] = res[i].url;
  //       }
  //       setHisDataURI(new_Data);
  //       setOpen(true);
  //       setLoading(false);
  //     });
  //   }
  // }, [hisMesuments]);

  // const getModuleData = async (authToken: string) => {
  //   getReportData(authToken, moduleId).then((res) => {
  //     console.log("%c Line:1175 🥔 res", "color:#e41a6a", res);
  //     if (res.success) {
  //       const {
  //         electrical_inspection_five,
  //         electrical_inspection_four,
  //         electrical_inspection_six,
  //         ...rpdata
  //       } = res.data;
  //       // setPdf(res?.data)
  //       // downloadPdf()
  //       const { relaxation_factors, dcir_values, voltage_vs_time_curve } =
  //         electrical_inspection_four;
  //       const { acir_parameters, graph_data } = electrical_inspection_five;
  //       const {
  //         charge_capacities,
  //         cc_dchg_curve,
  //         cccv_chg_curve,
  //         capacity_vs_smoothed_dQdV_curve,
  //       } = electrical_inspection_six;

  //       const new_reportData: any = {
  //         ...rpdata,
  //         acir_parameters: acir_parameters,
  //         dcir_values: dcir_values,
  //         relaxation_factors: relaxation_factors,
  //         charge_capacities: charge_capacities,
  //       };

  //       const observed_capacity =
  //         new_reportData.charge_capacities.reduce(
  //           (b: number, a: number) => b + a,
  //           0
  //         ) / new_reportData.charge_capacities.length;
  //       const rated_capacity = moduleId.includes("AAUAAB") ? 66 : 112;
  //       new_reportData["rated_capacity"] = rated_capacity;
  //       new_reportData["obresved_capacity"] = observed_capacity;
  //       new_reportData["estimated_eoh"] =
  //         (observed_capacity / rated_capacity) * 100;

  //       setReportData(new_reportData);

  //       const hisFour = seriesExtrectorCus("his4", graphArray.his4["yAxis"], [
  //         voltage_vs_time_curve,
  //       ]);

  //       const hisFive = seriesExtrector(
  //         "his5",
  //         graphArray.his5["xAxis"],
  //         graphArray.his5["yAxis"],
  //         graph_data
  //       );

  //       const hisSix = seriesExtrectorCus("his6", graphArray.his6["yAxis"], [
  //         cccv_chg_curve,
  //         cc_dchg_curve,
  //       ]);

  //       const hisSixTwo = seriesExtrectorCus(
  //         "his62",
  //         graphArray.his62["yAxis"],
  //         [capacity_vs_smoothed_dQdV_curve]
  //       );

  //       return Promise.all([hisFour, hisFive, hisSix, hisSixTwo]).then(
  //         (result: any) => {
  //           console.log(result);
  //           setTimeout(() => setHISMesurement(result), 10);
  //         }
  //       );
  //     }
  //   });
  // };

  // const seriesExtrector = (
  //   type: string,
  //   xAxis: string,
  //   plot: string[],
  //   data: any
  // ) => {
  //   return new Promise((resolve) => {
  //     const x_axis = [];
  //     const series: any = [];
  //     for (let i = 0; i < plot.length; i++) {
  //       series.push({ name: plot[i], data: [] });
  //       for (let j = 0; j < data.length; j++) {
  //         if (i === 0) {
  //           x_axis.push(data[j][xAxis]);
  //         }
  //         series[i]["data"].push(parseFloat(data[j][plot[i]]));
  //       }
  //     }

  //     resolve({ x_axis, series, type });
  //   });
  // };

  // const seriesExtrectorCus = (type: string, plot: string, data: any) => {
  //   return new Promise((resolve) => {
  //     const x_axis = [];
  //     const series: any = [];
  //     const series_length = data.length;
  //     for (let i = 0; i < series_length; i++) {
  //       series.push({ name: plot, data: [] });
  //       for (let j = 0; j < data[i].length; j++) {
  //         x_axis.push(data[i][j][1]);
  //         series[i]["data"].push(parseFloat(data[i][j][0]));
  //       }
  //     }

  //     resolve({ x_axis, series, type });
  //   });
  // };
  // const getTargetElement = () => document.getElementById("container");
  // const options: Options = {
  //   filename: `${moduleId}.pdf`,
  //   page: {
  //     // default is 'A4'
  //     format: "letter",
  //   },
  //   canvas: {},
  // };
  // const downloadPdf = () => {
  //   generatePDF(getTargetElement, options);
  // };

  function createData(
    name: any,
    calories: any,
    fat: any,
    carbs: any,
    protein: any
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  return (
    <>
      {" "}
      <div className="sm-stepForm-inner" id="kpi">
        {/* <ReportPDF2 /> */}
      </div>
      <FormControl variant="standard" className="sm-form-control">
        {/* <div className="sm-commen-box">
          <div className="graph_heading">
            <h4>Generate passport for</h4>
            <div className="graph_filter">
              <DatePicker
                isClearable
                showIcon
                toggleCalendarOnIconClick
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                placeholderText="Start date"
                className="startDate"
              />
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                selected={endDate}
                onChange={(date: any) => setEndDate(date)}
                placeholderText="End date"
                className="startDate"
              />
            </div>
            <div className="graph_filter">
              <Select
                value={OEMChart}
                options={OEM}
                className={` react-select `}
                classNamePrefix="OEM"
                placeholder="OEM"
                isClearable
                onChange={(e: any) => {
                  setOEMChart(e)
                }}
              />
              <Select
                value={modelChart}
                options={model}
                className={` react-select `}
                classNamePrefix="OEM"
                placeholder="Model"
                isClearable
                onChange={(e: any) => {
                  setModelChart(e)
                }}
              />
            </div>
          </div>
        </div> */}

        <br />
        <InputLabel htmlFor="component-simple">
          Generate Battery Passport
          {data.description && (
            <Tooltip className="sm-tooltip" title={data.description} arrow>
              <Button>i</Button>
            </Tooltip>
          )}
        </InputLabel>
        <MuiSelect
          variant="outlined"
          value={moduleId}
          onChange={handleChange}
          style={{ minWidth: "150px" }}
        >
          <MenuItem value="" style={{ display: "none" }}>
            Select Battery Module
          </MenuItem>
          {batteryModuleDropdown.map((option: any) => {
            return (
              <MenuItem value={option.packId} key={option.packId}>
                {option.packId}
              </MenuItem>
            );
          })}
        </MuiSelect>
        {/* {loading ? (
        <Button variant="contained">
          <span className="report_loader"></span>Generating Report
        </Button>
      ) : ( */}
        {/* {loading ? ( */}
        <Button
          disabled={!moduleId}
          variant="contained"
          onClick={() => {
            setDownload(true);
            window.open(`/#/passport/${moduleId}`, "_blank");
          }}
        >
          Publish
        </Button>
        {!download && (
          <Button disabled={!download} variant="contained">
            Download
          </Button>
        )}

        {/* : (
          // <Button>
          // <div>
          //   <PDFDownloadLink
          //     className="DownloadButton"
          //     document={<ReportPDF />}
          //   >
          //     {({ loading }) => (loading ? "Downloading..." : "Download")}
          //   </PDFDownloadLink>
          // </div>
          // </Button>
          // )
        }

        {/* <div style={{ width: 800, height: "0", opacity: "0" }}>
          {loading &&
            hisMesuments.map((measure: any, index: number) => {
              return (
                <ReactApexChart
                  key={index}
                  options={{
                    ...chartOption[measure.type],
                    chart: {
                      ...chartOption[measure.type].chart,
                      id: `chart_${index}`,
                    },
                    xaxis: {
                      ...chartOption[measure.type].xaxis,
                      categories: measure.x_axis,
                    },
                  }}
                  series={measure.series}
                  type="line"
                />
              );
            })}
        </div> */}
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className="sm-pdfModal"
        >
          <div className="custom_pdf_download">
            <div className="pdf_model_header">
              <p>Report</p>
              {/* {hisDataURI && reportData && (
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFContent
                        hisData={hisDataURI}
                        reportData={reportData}
                        module_id={moduleId}
                      />
                    }
                    fileName="fee_acceptance.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Download now!"
                    }
                  </PDFDownloadLink>
                </div>
              )} */}
              <div className="cancel_header">
                <Button
                  className="white-closeBtn"
                  onClick={() => setOpen(false)}
                ></Button>
              </div>
            </div>
            <div className="pdf_content">
              {hisDataURI && reportData && (
                <PDFViewer
                  width={"100%"}
                  height={"630"}
                  style={{ margin: "0 auto" }}
                >
                  <PDFContent
                    hisData={hisDataURI}
                    reportData={reportData}
                    module_id={moduleId}
                  />
                </PDFViewer>
              )}
            </div>
          </div>
        </Modal>
      </FormControl>
    </>
  );
};
