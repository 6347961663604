// src/reducer/inspectionReducer.tsx
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface dataInitState {
  [key: string]: any;
}

const initialState: dataInitState = {
  formData: null,
  step: "step_14",
  toggle: true,
  module: 0,
  packId: "",
  focus: false,
};

export const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    handleFormData: (state, action: PayloadAction<any>) => {
      state.formData = action.payload;
    },
    handleFieldData: (state, action: PayloadAction<any>) => {
      const { step, key, value } = action.payload;
      state.formData = {
        ...state.formData,
        [step]: {
          ...(state.formData[step] || {}),
          [key]: value,
        },
      };
    },
    resetStepData: (state, action: PayloadAction<any>) => {
      const { step, value } = action.payload;
      state.formData[step] = value;
    },
    resetToggle: (state, action: PayloadAction<any>) => {
      state.toggle = action.payload;
    },
    handleStep: (state, action: PayloadAction<any>) => {
      state.step = action.payload;
    },
    handleModule: (state, action: PayloadAction<any>) => {
      state.module = action.payload;
    },
    handlePackId: (state, action: PayloadAction<any>) => {
      state.packId = action.payload;
    },
    handleFocus: (state, action: PayloadAction<any>) => {
      state.focus = action.payload;
    },
  },
});

export const {
  handleFormData,
  handleFieldData,
  handleStep,
  resetStepData,
  resetToggle,
  handleModule,
  handlePackId,
  handleFocus,
} = inspectionSlice.actions;
export default inspectionSlice.reducer;
