import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select as MuiSelect,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import * as htmlToImage from "html-to-image";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { Ref, useEffect, useRef, useState } from "react";
import PDFContent from "../../../../components/PDFGen/PDFContent";
import { Field } from "../../types";
import ReactApexChart from "react-apexcharts";
import jsPDF from "jspdf";
import topView from "../../../../assets/images/top_view.png";
import bottmomView from "../../../../assets/images/bottom_view.png";
import leftView from "../../../../assets/images/left_view.png";
import rightView from "../../../../assets/images/right_view.png";
import backView from "../../../../assets/images/back_view.png";
import frontView from "../../../../assets/images/front_view.png";

import top_view from "../../../../assets/images/jaguar/top_view.png";
import bottmom_view from "../../../../assets/images/jaguar/bottom_view.png";
import left_view from "../../../../assets/images/jaguar/left_view.png";
import right_view from "../../../../assets/images/jaguar/right_view.png";
import back_view from "../../../../assets/images/jaguar/back_view.png";
import front_view from "../../../../assets/images/jaguar/front_view.png";

import top from "../../../../assets/images/AAD/top.jpg";
import bottom from "../../../../assets/images/AAD/bottom.jpg";
import front from "../../../../assets/images/AAD/front.jpg";
import back from "../../../../assets/images/AAD/back.jpg";
import right from "../../../../assets/images/AAD/right.jpg";
import left from "../../../../assets/images/AAD/left.jpg";
import top2 from "../../../../assets/images/AAB/top2.jpg";
import bottom2 from "../../../../assets/images/AAB/bottom2.jpg";
import front2 from "../../../../assets/images/AAB/front2.jpg";
import back2 from "../../../../assets/images/AAB/back2.jpg";
import right2 from "../../../../assets/images/AAB/right2.jpg";
import left2 from "../../../../assets/images/AAB/left2.jpg";
import generatePDF, { Options } from "react-to-pdf";
import { chartOption } from "../../../../components/Chart/chartOption";
import {
  getBatteryMOdules,
  getModel,
  getModuleIdData,
  getOEM,
  getReportData,
  getReportModuleId,
} from "../../../../service/apiService";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import html2canvas from "html2canvas";
import Header from "./Header";
import {
  reversedData,
  grapOne,
  grapTwo,
  VoltageData,
  voltage,
  DvaData,
  DVA,
  nyquist,
  VoltageData2,
  findPeaks,
  graphData,
} from "./Graphdata";
import { exportMultipleChartsToPdf } from "./Utils";
import ImageWithError from "./ImageWithError";
import axios from "axios";
import { saveAs } from "file-saver";

const graphArray: any = {
  his4: {
    category: "single",
    xAxis: "DataPoint",
    yAxis: "Voltage(V)",
  },
  his5: {
    category: "dual",
    xAxis: "Zreal (ohm)",
    yAxis: [" -Zimag (ohm)", " Fit1 - -Zimag (ohm)"],
  },
  his6: {
    category: "single",
    xAxis: "Capacity(Ah)",
    yAxis: "Voltage(V)",
  },
  his62: {
    category: "single",
    xAxis: "Capacity (Ah)",
    yAxis: "dQ/dV (Ah/V)",
  },
};

export const PublishReport = ({
  data,
  status,
}: {
  data: Field;
  status: boolean;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [hisMesuments, setHISMesurement] = React.useState<any>([]);
  const [hisDataURI, setHisDataURI] = React.useState<any>(null);
  const [pdf, setPdf] = React.useState<any>(null);
  const [hisSkip, setHisSkip] = React.useState<any>(false);
  console.log("%c Line:92 🍰 pdf", "color:#f5ce50", pdf, hisSkip);
  const [reportData, setReportData] = React.useState<any>(null);
  // console.log("%c Line:89 🥤 reportData", "color:#465975", reportData)
  const [loading, setLoading] = React.useState<any>(false);
  const [moduleId, setModuleId] = useState<any>("");
  // console.log("%c Line:66 🥒 moduleId", "color:#e41a6a", moduleId)
  const [batteryModule, setBatteryModule] = useState<any>([]);
  const [batteryModuleDropdown, setBatteryModuleDropdown] = useState<any>([]);
  const [OEM, setOEM] = React.useState<any>([]);
  const [model, setModel] = React.useState<any>([]);
  const page1 = useRef<any>(null);
  const page2 = useRef<any>(null);
  const page3 = useRef<any>(null);
  const page4 = useRef<any>(null);
  const [OEMChart, setOEMChart] = React.useState<any>({
    label: "All",
    value: "",
  });
  const [modelChart, setModelChart] = React.useState<any>({
    label: "All",
    value: "",
    oem_id: "",
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    getModel()
      .then((res) => {
        if (res?.data) {
          const arr: any = [];

          const filter: any = res?.data?.data?.filter(
            (obj: any) => obj?.oem === OEMChart?.value
          );
          filter?.map((obj: any) => {
            arr.push({
              label: obj?.name,
              value: obj?.code,
              oem_id: obj?.oem,
            });
          });

          setModel(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [OEMChart]);

  const [state2, setState2] = useState({
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        width: "100%",
        height: "100%",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        width: 2,
      },
      title: {
        text: "Pulse DCIR Curve",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      legend: {
        show: true,
        // position: "right",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // showForSingleSeries: true,
        // showForNullSeries: true,
        // showForZeroSeries: true,
      },
      xaxis: {
        tickAmount: 10,
        title: {
          text: "Time (Min.)",
        },
        decimalsInFloat: 1,
      },
      yaxis: [
        {
          title: {
            text: "Voltage (V)",
          },
          decimalsInFloat: 1,
        },
        {
          opposite: true,
          title: {
            text: "Current (A)",
            style: {
              color: "#21b017",
            },
          },
          decimalsInFloat: 1,
        },
      ],
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },

      tooltip: {
        enabled: true,
      },
      colors: ["#000", "#21b017"],
    },

    series: [
      // {
      //   name: "Voltage",
      //   data: VoltageData(voltage),
      //   type: "line",
      //   yAxisIndex: 0,
      // },
      // {
      //   name: "Current",
      //   data: VoltageData2(voltage),
      //   type: "line",
      //   yAxisIndex: 1,
      // },
      {
        name: "Voltage",
        data: [],
        type: "line",
        yAxisIndex: 0,
      },
      {
        name: "Current",
        data: [],
        type: "line",
        yAxisIndex: 1,
      },
    ],
  });

  const [state3, setState3] = useState({
    options: {
      legend: {
        show: true,
        // position: "left",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // horizontalAlign: "center",
        // border: "1px solid #ccc",
        // left: "80px",
        // background: "white",
        // bottom: "80px",
        labels: {
          text: "CC Current @ c/3",
        },
      },
      chart: {
        type: "line",
        toolbar: {
          show: false, // Hide toolbar
        },
        width: "100%",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      markers: {
        size: 3,
        colors: ["#fc0303", "#0324fc"],
        strokeColors: "#fff",
        strokeWidth: 2,
        shape: ["square", "circle"],
        hover: {
          size: 5,
        },
      },
      stroke: {
        width: 2,
        dashArray: [2, 0],
      },
      title: {
        text: "Nyquist Plot",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      xaxis: {
        tickAmount: 6,
        min: 2,
        max: 5,
        title: {
          text: "Real Z (mΩ)",
        },
        decimalsInFloat: 1,
        labels: {
          formatter: function (value: any) {
            return value.toFixed(1);
          },
        },
      },
      yaxis: {
        tickAmount: 10,
        title: {
          text: "imaginary Z (mΩ)",
        },
        decimalsInFloat: 1,
        // min: 0,
        // max: 5,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
      colors: ["#fc0303", "#0324fc"],
    },
    series: [
      {
        name: "Actual Data",
        data: nyquist(graphData).redData,
      },
      {
        name: "Fitted Data",
        data: nyquist(graphData).blueData,
      },
      // {
      //   name: "Actual Data",
      //   data: [],
      // },
      // {
      //   name: "Fitted Data",
      //   data: [],
      // },
    ],
  });
  // console.log("nyquist(voltage).redData: ", nyquist(graphData).redData);
  // console.log("nyquist(voltage).blueData: ", nyquist(graphData).blueData);
  const [state4, setState4] = useState({
    options: {
      // title: {
      //   text: "dQ/dV Curve with Identified Trough and Peak",
      //   align: 'center',
      //   margin: 2,
      //   offsetX: 0,
      //   offsetY: 0,
      //   floating: true,
      //   style: {
      //     fontSize: '10px',
      //     fontWeight: '500',
      //     color: '#000'
      //   },
      // },
      annotations: {
        points: [
          {
            x: findPeaks(DVA).highestPeak.x,
            y: findPeaks(DVA).highestPeak.y,
            marker: {
              size: 3,
              fillColor: "red",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              offsetY: 0,
              style: {
                color: "#000",
                border: "none",
              },

              text: "Last Peak",
            },
          },
          {
            x: findPeaks(DVA).lowestPeak.x,
            y: findPeaks(DVA).lowestPeak.y,
            marker: {
              size: 3,
              fillColor: "red",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              offsetY: 0,
              style: {
                color: "#000",
              },

              text: "First Trough",
            },
          },
        ],
      },
      legend: {
        show: true,
        // position: "right",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // showForSingleSeries: true,
        // showForNullSeries: true,
        // showForZeroSeries: true,
      },
      chart: {
        type: "line",
        toolbar: {
          show: false, // Hide toolbar
        },
        width: "100%",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        width: 2,
        curve: "straight",
      },
      title: {
        text: " dQ/dV vs Capacity Curve",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      xaxis: {
        tickAmount: 8,
        title: {
          text: "Capacity(Ah)",
        },
        decimalsInFloat: 1,
      },
      yaxis: {
        title: {
          text: "dQ/dV",
        },
        decimalsInFloat: 1,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
      colors: ["#0324fc", "#ff1100"],
    },
    series: [
      // {
      //   name: "Smoothed dQ/dV",
      //   data: DvaData(DVA),
      // },
      {
        name: "Smoothed dQ/dV",
        data: [],
      },
      {
        name: "Identified Points",
        data: [],
      },
    ],
  });

  const [state1, setState1] = useState({
    options: {
      title: {
        text: "Voltage vs Capacity for the Second Cycle",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      legend: {
        show: true,
        // position: "left",
        // floating: true,
        // offsetX: "40px",
        // offsetY: "50px",
        // horizontalAlign: "center",
        // border: "1px solid #ccc",
        // left: "80px",
        // background: "white",
        // bottom: "80px",
        customLegendItems: [
          "CC current @  C/3",
          "Capacity (Ah)",
          "Voltage (Vh)",
        ],
        fillColors: ["#fff", "#fc0303", "#0324fc"],
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: ["#fff", "#fc0303", "#0324fc"],
          radius: 12,
        },
      },
      chart: {
        type: "line",
        toolbar: {
          show: false, // Hide toolbar
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        width: "100%",
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Line Chart Example",
      // },
      xaxis: {
        categories: [],
        title: {
          text: "Capacity (Ah)",
          fontSize: "10px",
        },
        tickAmount: 10,
        decimalsInFloat: 1,
      },
      yaxis: {
        title: {
          text: "Voltage (v)",
          fontSize: "10px",
        },
        labels: {
          formatter: function (value: any) {
            return value + 0.5;
          },
        },
        decimalsInFloat: 1,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#fc0303", "#0324fc"],
      tooltip: {
        enabled: true,
      },
    },

    series: [
      {
        name: "Charge",
        data: reversedData(grapOne),
      },
      {
        name: "Discharge",
        data: reversedData(grapTwo),
      },
      // {
      //   name: "Charge",
      //   data: [],
      // },
      // {
      //   name: "Discharge",
      //   data: [],
      // },
    ],
  });

  // console.log("state1", state1);

  const [state, setState] = useState({
    series: [
      {
        data: [21, 22],
        name: "value",
      },
    ],
    options: {
      title: {
        text: "Coulombic Efficiency",
        align: "center",
        margin: 2,
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: "12px",
          fontWeight: "700",
          color: "#000",
        },
      },
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        width: "100%",
      },
      colors: ["#059911", "#0324fc"],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        decimalsInFloat: 0,
        categories: ["Cycle 2", "Cycle 3"],
        tickPlacement: "on",
      },

      yaxis: {
        decimalsInFloat: 0,
        title: {
          // text: "Value",
        },
      },
    },
  });
  const handleDropdown = () => {
    getOEM()
      .then((res) => {
        if (res?.data) {
          const arr: any = [{ label: "All", value: "" }];
          res?.data?.data?.map((obj: any) => {
            arr.push({ label: obj?.name, value: obj?.code });
          });
          setOEM(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // getModel()
    //   .then((res) => {
    //     if (res?.data) {
    //       const arr: any = [{label: "All", value: "", oem_id: ""}]

    //       res?.data?.data?.map((obj: any) => {
    //         arr.push({
    //           label: obj?.name,
    //           value: obj?.code,
    //           oem_id: obj?.oem_id,
    //         })
    //       })

    //       setModel(arr)
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error)
    //   })
  };
  const handleModuleId = () => {
    if (!startDate) {
      return;
    }
    if (!endDate) {
      return;
    }
    if (!OEMChart) {
      return;
    }
    if (!modelChart) {
      return;
    }
    const params: any = {
      startDate: moment(startDate)?.format("DD/MM/YYYY"),
      endDate: moment(endDate)?.format("DD/MM/YYYY"),
      oem: OEMChart?.value,
      model: modelChart?.value,
    };
    getReportModuleId(params)
      .then((res) => {
        // console.log("%c Line:110 🍣 res", "color:#b03734", res);
        if (res?.success) {
          setBatteryModuleDropdown(res?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleModuleIdData = () => {
    setLoading(true);
    const body: any = { moduleId: moduleId };
    setPdf("");
    setHisSkip(false);
    getModuleIdData(body)
      .then((res) => {
        // console.log("%c Line:151 🍺 res", "color:#42b983", res);
        if (res?.success) {
          // setState({
          //   ...state,
          //   series: [
          //     {
          //       name: "Value",
          //       data: [
          //         res?.data?.data?.electrical_inspection_six
          //           ?.charge_capacities?.[0],
          //         res?.data?.data?.electrical_inspection_six
          //           ?.charge_capacities?.[1],
          //       ] as any,
          //     },
          //   ],
          // });
          // const step1: number = Math.ceil(
          //   res?.data?.data?.electrical_inspection_six?.cc_dchg_curve?.length /
          //     40
          // );
          // const graph1: any = [];
          // for (
          //   let i = 0;
          //   i <
          //   res?.data?.data?.electrical_inspection_six?.cc_dchg_curve?.length;
          //   i += 10
          // ) {
          //   graph1.push(
          //     res?.data?.data?.electrical_inspection_six?.cc_dchg_curve?.[i]
          //   );
          // }
          // const step2: number = Math.ceil(
          //   res?.data?.data?.electrical_inspection_six?.cccv_chg_curve?.length /
          //     40
          // );
          // const graph2: any = [];
          // for (
          //   let i = 0;
          //   i <
          //   res?.data?.data?.electrical_inspection_six?.cccv_chg_curve?.length;
          //   i += 10
          // ) {
          //   graph2.push(
          //     res?.data?.data?.electrical_inspection_six?.cccv_chg_curve?.[i]
          //   );
          // }
          // const reversed1: any = reversedData(graph1);
          // const reversed2: any = reversedData(graph2);

          // setState1({
          //   ...state1,
          //   options: {
          //     ...state1.options,
          //     xaxis: {
          //       ...state1.options.xaxis,
          //       tickAmount: 20,
          //     },
          //     legend: {
          //       ...state1.options.legend,
          //       customLegendItems:
          //         res?.data?.data?.oem == "Jaguar Land Rover"
          //           ? ["CC current @  C/4", "Capacity (Ah)", "Voltage (Vh)"]
          //           : ["CC current @  C/3", "Capacity (Ah)", "Voltage (Vh)"],
          //     },
          //   },
          //   series: [
          //     {
          //       name: "Charge",
          //       data: reversed2,
          //     },
          //     {
          //       name: "Discharge",
          //       data: reversed1,
          //     },
          //   ],
          // });
          // const voltageValues: any = VoltageData(
          //   res.data.data?.electrical_inspection_four?.voltage_vs_time_curve
          // );
          // const currentValues: any = VoltageData2(
          //   res.data.data?.electrical_inspection_four?.voltage_vs_time_curve
          // );
          // setState2({
          //   ...state2,
          //   series: [
          //     {
          //       name: "Voltage",
          //       data: voltageValues,
          //       type: "line",
          //       yAxisIndex: 0,
          //     },
          //     {
          //       name: "Current",
          //       data: currentValues,
          //       type: "line",
          //       yAxisIndex: 1,
          //     },
          //   ],
          // });
          // const step3: number = Math.ceil(
          //   res?.data?.data?.electrical_inspection_five?.graph_data?.length / 20
          // );
          // const graph3: any = [];
          // for (
          //   let i = 0;
          //   i < res?.data?.data?.electrical_inspection_five?.graph_data?.length;
          //   i += step3
          // ) {
          //   graph3.push(
          //     res?.data?.data?.electrical_inspection_five?.graph_data[i]
          //   );
          // }

          // const redData: any = nyquist(
          //   res?.data?.data?.electrical_inspection_five?.graph_data
          // ).redData;
          // const blueData: any = nyquist(
          //   res?.data?.data?.electrical_inspection_five?.graph_data
          // ).blueData;
          // setState3({
          //   ...state3,
          //   series: [
          //     {
          //       name: "Actual Data",
          //       data: redData,
          //     },
          //     {
          //       name: "Fitted Data",
          //       data: blueData,
          //     },
          //   ],
          //   options: {
          //     ...state3.options,
          //     xaxis: {
          //       ...state3.options.xaxis,
          //       min: Math.min(
          //         ...redData.map((d: any) => d[0] - 0.1),
          //         ...blueData.map((d: any) => d[0] - 0.1)
          //       ),
          //       max: Math.max(
          //         ...redData.map((d: any) => d[0] + 0.1),
          //         ...blueData.map((d: any) => d[0] + 0.1)
          //       ),
          //     },
          //   },
          // });
          // const graph4: any = [];
          // for (
          //   let i = 0;
          //   i <
          //   res?.data?.data?.electrical_inspection_six
          //     ?.capacity_vs_smoothed_dQdV_curve?.length;
          //   i += 20
          // ) {
          //   graph4.push(
          //     res?.data?.data?.electrical_inspection_six
          //       ?.capacity_vs_smoothed_dQdV_curve[i]
          //   );
          // }

          // setState4({
          //   ...state4,
          //   options: {
          //     ...state4.options,
          //     annotations: {
          //       points: [
          //         {
          //           x: findPeaks(graph4).highestPeak.x,
          //           y: findPeaks(graph4).highestPeak.y,
          //           marker: {
          //             size: 3,
          //             fillColor: "red",
          //             strokeColor: "red",
          //             radius: 2,
          //             cssClass: "apexcharts-custom-class",
          //           },
          //           label: {
          //             offsetY: 0,
          //             style: {
          //               color: "#000",
          //               border: "none",
          //             },

          //             text: "Last Peak",
          //           },
          //         },
          //         {
          //           x: findPeaks(graph4).lowestPeak.x,
          //           y: findPeaks(graph4).lowestPeak.y,
          //           marker: {
          //             size: 3,
          //             fillColor: "red",
          //             strokeColor: "red",
          //             radius: 2,
          //             cssClass: "apexcharts-custom-class",
          //           },
          //           label: {
          //             offsetY: 0,
          //             style: {
          //               color: "#000",
          //             },

          //             text: "First Trough",
          //           },
          //         },
          //       ],
          //     },
          //   },
          //   series: [
          //     {
          //       name: "Smooth dQ/dV",
          //       data: graph4,
          //     },
          //     {
          //       name: "Identified Points",
          //       data: [],
          //     },
          //   ],
          // });
          setPdf(res?.data?.data);
          if (
            typeof res?.data?.data?.electrical_inspection_four === "object" &&
            res?.data?.data?.electrical_inspection_four !== null &&
            Object.keys(res?.data?.data?.electrical_inspection_four)?.length ===
              0
          ) {
            setHisSkip(true);
          } else {
            setHisSkip(false);
          }
        }
        setLoading(false);
        // setBatteryModuleDropdown(res?.data?.data)
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  React.useEffect(() => {
    handleModuleId();
  }, [startDate, endDate, modelChart, OEMChart]);
  React.useEffect(() => {
    if (moduleId) {
      handleModuleIdData();
    }
  }, [moduleId]);

  React.useEffect(() => {
    const authToken = localStorage.getItem("accessToken") || "";
    handleDropdown();
    getBatteryMOdules(authToken).then((res) => {
      if (res.success) {
        setBatteryModule(res.data);
        if (res.data.length > 0) {
          // setModuleId(res.data[0].identifier)
          // console.log(
          //   "%c Line:986 🥚 moduleId",
          //   "color:#6ec1c2",
          //   res.data[0].identifier,
          // )
        }
      }
    });
  }, []);

  const handleContinue = () => {
    const authToken = localStorage.getItem("accessToken") || "";
    if (!hisMesuments.length || !reportData) {
      setLoading(true);

      if (!hisMesuments.length) {
        getModuleData(authToken);
        handleModuleIdData();
      }
    } else {
      setOpen(true);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setModuleId(event.target.value as string);
    // console.log("%c Line:1008 🍒 moduleId", "color:#fca650", event.target.value)
  };

  React.useEffect(() => {
    if (hisMesuments.length > 0 && loading) {
      const promiseArray = [];
      for (let i = 0; i < hisMesuments.length; i++) {
        promiseArray.push(
          ApexCharts.exec(`chart_${i}`, "dataURI").then(({ imgURI }: any) => {
            return { type: hisMesuments[i].type, url: imgURI };
          })
        );
      }

      Promise.all(promiseArray).then((res) => {
        const new_Data: any = {};
        for (let i = 0; i < res.length; i++) {
          new_Data[res[i].type] = res[i].url;
        }
        setHisDataURI(new_Data);
        setOpen(true);
        setLoading(false);
      });
    }
  }, [hisMesuments]);

  const getModuleData = async (authToken: string) => {
    getReportData(authToken, moduleId).then((res) => {
      // console.log("%c Line:1175 🥔 res", "color:#e41a6a", res);
      if (res.success) {
        const {
          electrical_inspection_five,
          electrical_inspection_four,
          electrical_inspection_six,
          ...rpdata
        } = res.data;
        // setPdf(res?.data)
        // downloadPdf()
        const { relaxation_factors, dcir_values, voltage_vs_time_curve } =
          electrical_inspection_four;
        const { acir_parameters, graph_data } = electrical_inspection_five;
        const {
          charge_capacities,
          cc_dchg_curve,
          cccv_chg_curve,
          capacity_vs_smoothed_dQdV_curve,
        } = electrical_inspection_six;

        const new_reportData: any = {
          ...rpdata,
          acir_parameters: acir_parameters,
          dcir_values: dcir_values,
          relaxation_factors: relaxation_factors,
          charge_capacities: charge_capacities,
        };

        const observed_capacity =
          new_reportData.charge_capacities.reduce(
            (b: number, a: number) => b + a,
            0
          ) / new_reportData.charge_capacities.length;
        const rated_capacity = moduleId.includes("AAUAAB") ? 66 : 112;
        new_reportData["rated_capacity"] = rated_capacity;
        new_reportData["obresved_capacity"] = observed_capacity;
        new_reportData["estimated_eoh"] =
          (observed_capacity / rated_capacity) * 100;

        setReportData(new_reportData);

        const hisFour = seriesExtrectorCus("his4", graphArray.his4["yAxis"], [
          voltage_vs_time_curve,
        ]);

        const hisFive = seriesExtrector(
          "his5",
          graphArray.his5["xAxis"],
          graphArray.his5["yAxis"],
          graph_data
        );

        const hisSix = seriesExtrectorCus("his6", graphArray.his6["yAxis"], [
          cccv_chg_curve,
          cc_dchg_curve,
        ]);

        const hisSixTwo = seriesExtrectorCus(
          "his62",
          graphArray.his62["yAxis"],
          [capacity_vs_smoothed_dQdV_curve]
        );

        return Promise.all([hisFour, hisFive, hisSix, hisSixTwo]).then(
          (result: any) => {
            console.log(result);
            setTimeout(() => setHISMesurement(result), 10);
          }
        );
      }
    });
  };

  const seriesExtrector = (
    type: string,
    xAxis: string,
    plot: string[],
    data: any
  ) => {
    return new Promise((resolve) => {
      const x_axis = [];
      const series: any = [];
      for (let i = 0; i < plot.length; i++) {
        series.push({ name: plot[i], data: [] });
        for (let j = 0; j < data.length; j++) {
          if (i === 0) {
            x_axis.push(data[j][xAxis]);
          }
          series[i]["data"].push(parseFloat(data[j][plot[i]]));
        }
      }

      resolve({ x_axis, series, type });
    });
  };

  const seriesExtrectorCus = (type: string, plot: string, data: any) => {
    return new Promise((resolve) => {
      const x_axis = [];
      const series: any = [];
      const series_length = data.length;
      for (let i = 0; i < series_length; i++) {
        series.push({ name: plot, data: [] });
        for (let j = 0; j < data[i].length; j++) {
          x_axis.push(data[i][j][1]);
          series[i]["data"].push(parseFloat(data[i][j][0]));
        }
      }

      resolve({ x_axis, series, type });
    });
  };
  const getTargetElement = () => document.getElementById("container");
  const options: Options = {
    filename: `${pdf?.module_id}.pdf`,
    page: {
      // default is 'A4'
      format: "letter",
    },
    canvas: {},
  };
  const downloadPdf = () => {
    generatePDF(getTargetElement, options);
    // captureAndGeneratePDF()
  };

  const captureAndGeneratePDF = async () => {
    const pdf: any = new jsPDF();
    const options = {
      width: page1.current.offsetWidth, // Width of div
      height: page1.current.offsetHeight, // Height of div
      scale: 0.73, // No scaling
      useCORS: true,
    };
    // Allow cross-origin images };

    // Capture screenshots of div sections
    const div1Image = await html2canvas(page1.current, options);
    const div2Image = await html2canvas(page2.current, options);
    const div3Image = await html2canvas(page3.current, options);
    const div4Image = await html2canvas(page4.current, options);

    // Add div section screenshots to PDF
    pdf.addImage(div1Image.toDataURL(), "PNG", 10, 10);
    pdf.addPage();
    pdf.addImage(div2Image.toDataURL(), "PNG", 10, 10);
    pdf.addPage();
    pdf.addImage(div3Image.toDataURL(), "PNG", 10, 10);
    pdf.addPage();
    pdf.addImage(div4Image.toDataURL(), "PNG", 10, 10);

    // Save or display the PDF
    pdf.save(moduleId + ".pdf");
  };
  // useEffect(() => {
  //   if (pdf) {
  //     console.log("%c Line:543 🥔 graph", "color:#4fff4B", pdf)
  //     generatePDF(getTargetElement, options)
  //   }
  // }, [pdf])

  function createData(
    name: any,
    calories: any,
    fat: any,
    carbs: any,
    protein: any
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  const isOemSerialId = (obj: any) => {
    if (pdf) {
      if (obj?.oem_serial_id) {
        return true;
      } else {
        return false;
      }
    }
  };
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async (e: any, name: any, url: any) => {
    e.stopPropagation();
    if (name && url) {
      try {
        const response = await axios.get(url, {
          responseType: "blob",
        });
        const filename = name;
        saveAs(response.data, filename);
      } catch (error) {
        console.error(`Error downloading ${name}:`, error);
      }
    }
  };
  return (
    <FormControl variant="standard" className="sm-form-control">
      <div className="sm-commen-box">
        <div className="graph_heading">
          <h4>Generate report for</h4>
          <div className="graph_filter">
            <DatePicker
              isClearable
              showIcon
              toggleCalendarOnIconClick
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              placeholderText="Start date"
              className="startDate"
            />
            <DatePicker
              showIcon
              toggleCalendarOnIconClick
              isClearable
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              placeholderText="End date"
              className="startDate"
            />
          </div>
          <div className="graph_filter">
            <Select
              value={OEMChart}
              options={OEM}
              className={` react-select `}
              classNamePrefix="OEM"
              placeholder="OEM"
              isClearable
              onChange={(e: any) => {
                setOEMChart(e);
              }}
            />
            <Select
              value={modelChart}
              options={model}
              className={` react-select `}
              classNamePrefix="OEM"
              placeholder="Model"
              isClearable
              onChange={(e: any) => {
                setModelChart(e);
              }}
            />
          </div>
        </div>
      </div>
      <InputLabel htmlFor="component-simple">
        Generate Report
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
      </InputLabel>

      <MuiSelect
        variant="outlined"
        value={moduleId}
        onChange={handleChange}
        style={{ minWidth: "150px" }}
      >
        <MenuItem value="" style={{ display: "none" }}>
          Select Battery Module
        </MenuItem>
        {batteryModuleDropdown.map((option: any) => {
          return (
            <MenuItem value={option.battery_module} key={option.battery_module}>
              {option.battery_module}
            </MenuItem>
          );
        })}
      </MuiSelect>
      <Button
        variant="contained"
        disabled={!status || !moduleId}
        onClick={(e) => {
          // window.open(
          //   // window.location.origin + "/#/battery-report-download/" + moduleId,
          //   pdf?.reportUrl,
          //   "_blank"
          // );
          downloadFile(e, pdf?.battery_module, pdf?.reportUrl);
        }}
      >
        {/* <span className="report_loader"></span> */}
        Download Report
      </Button>
      {/* {loading ? (
        <Button variant="contained">
          <span className="report_loader"></span>Generating Report
        </Button>
      ) : (
        <>
          {" "}
          {!isDownloading && (
            <Button
              variant="contained"
              onClick={() => {
                // downloadPdf()
                // handleContinue()
                handleModuleIdData();
              }}
              disabled={!status || !moduleId}
            >
              {data.name}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={async () => {
              // downloadPdf()
              setIsDownloading(true);
              const res = await exportMultipleChartsToPdf(pdf?.module_id);
              console.log("res: ", res);
              if (res) {
                setIsDownloading(false);
              }
            }}
            disabled={!pdf || isDownloading}
          >
            {isDownloading ? (
              <>
                <span className="report_loader"></span> Downloading...
              </>
            ) : (
              "Download"
            )}
          </Button>
        </>
      )} */}

      <div style={{ width: "100%" }}>
        {pdf && (
          <iframe
            src={`${pdf?.reportUrl}#toolbar=0`}
            style={{
              width: "100%",
              height: "100%",
              minHeight: "800px",
              border: "none",
            }}
            title="PDF Viewer"
          />
        )}
      </div>
      {pdf &&
        show &&
        (!hisSkip ? (
          <div
            id="container"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "100%" }}>
              <Grid container justifyContent={"center"}>
                <div className="custom-chart">
                  <Header />
                  <div className="Inner_padding">
                    <Grid
                      item
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <h2
                        style={{
                          width: "80%",
                          fontSize: "35px",
                          textAlign: "center",
                          fontWeight: "700",
                          color: "#3498d2",
                          marginInline: "auto",
                        }}
                      >
                        Module Id: {pdf?.module_id}
                      </h2>
                      {pdf?.oem == "Jaguar Land Rover" ? (
                        <div
                          className="Report_desc"
                          style={{
                            marginTop: "20px",
                            width: "100%",
                            marginInline: "auto",
                            textAlign: "center",
                          }}
                        >
                          The Device Under Test is a{" "}
                          <span style={{ fontWeight: "800" }}>
                            Jaguar Land Rover X590{" "}
                          </span>{" "}
                          module.{" "}
                          {isOemSerialId(pdf) ? (
                            <>
                              {`The Samsar Module ID `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` is associated with the OEM ID of `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.oem_serial_id}.{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              {`The OEM ID for the Samsar Module ID `}{" "}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` was not legible to retrieve.`}
                            </>
                          )}
                          <br />
                        </div>
                      ) : (
                        <div
                          className="Report_desc"
                          style={{
                            marginTop: "20px",
                            width: "100%",
                            marginInline: "auto",
                            textAlign: "center",
                          }}
                        >
                          The Device Under Test is a{" "}
                          <span style={{ fontWeight: "800" }}>{pdf?.oem}</span>
                          {pdf?.oem !== "Caterpillar" && " "}
                          {pdf?.oem !== "Caterpillar" && pdf?.oem_model}.{" "}
                          {/* which was deemed fit for further
                        evaluation at an Open Circuit Voltage of {pdf?.ocv} V
                        with an internal ACIR at 1kHz equal to {pdf?.acir} m.{" "} */}
                          {isOemSerialId(pdf) ? (
                            <>
                              {`The Samsar Module ID `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` is associated with the OEM ID of `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.oem_serial_id}.{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              {`The OEM ID for the Samsar Module ID `}{" "}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` was not legible to retrieve.`}
                            </>
                          )}
                          <br />
                          {/* Below are the snapshot of each phase the battery module
                    followed by its health analysis. */}
                        </div>
                      )}
                    </Grid>
                    <div className="tableFlex" style={{ gap: "10px" }}>
                      {pdf?.oem == "Jaguar Land Rover" ? (
                        <TableContainer
                          style={{
                            width: "50%",
                            borderRadius: "0px",
                            boxShadow: "none",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    width: "100%",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Rated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Rated Capacity @C/4, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  232
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Maximum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  12.6
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Nominal
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  10.8
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Minimum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  7.5
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <TableContainer
                          style={{
                            width: "50%",
                            borderRadius: "0px",
                            boxShadow: "none",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    width: "100%",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Rated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Rated Capacity @C/3, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar"
                                    ? "105"
                                    : pdf?.oem === "Jaguar Land Rover"
                                    ? "232"
                                    : pdf?.oem === "Nissan" &&
                                      pdf?.oem_model === "Gen 4"
                                    ? "112"
                                    : pdf?.oem === "Nissan" &&
                                      pdf?.oem_model === "Gen 2"
                                    ? "66"
                                    : "84"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Maximum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar" ? "58.8" : "8.4"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Nominal
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar" ? "50" : "7.2"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Minimum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar" ? "42" : "5"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}

                      {/* table 2 */}
                      {pdf?.oem == "Jaguar Land Rover" ? (
                        <TableContainer
                          style={{
                            width: "50%",
                            boxShadow: "none",
                            borderRadius: "0px",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                color: "white",
                                boxShadow: "none",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Estimated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Observed Capacity @C/4, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? Number(
                                        pdf?.electrical_inspection_six?.estimated_specification[0][
                                          "Observed Capacity @C/3, 25°C"
                                        ]?.split(" ")[0]
                                      ).toFixed(1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                {" "}
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Estimated SOH
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]?.split(".")[0] +
                                      "." +
                                      pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]
                                        ?.split(".")[1]
                                        .slice(0, 1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  %
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <TableContainer
                          style={{
                            width: "50%",
                            boxShadow: "none",
                            borderRadius: "0px",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                color: "white",
                                boxShadow: "none",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Estimated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Observed Capacity @C/3, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? Number(
                                        pdf?.electrical_inspection_six?.estimated_specification[0][
                                          "Observed Capacity @C/3, 25°C"
                                        ]?.split(" ")[0]
                                      ).toFixed(1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                {" "}
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Estimated SOH
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]?.split(".")[0] +
                                      "." +
                                      pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]
                                        ?.split(".")[1]
                                        .slice(0, 1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  %
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </div>
                    {pdf?.oem == "Jaguar Land Rover" ? (
                      <div className="imageGrid">
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images?.top_image
                                ? pdf?.images?.top_image
                                : top_view
                            }
                            alt="hs1"
                          />
                          <p>Top View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images
                                ? pdf?.images?.bottom_image
                                : bottmom_view
                            }
                            alt="hs1"
                          />
                          <p>Bottom View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images ? pdf?.images?.left_image : left_view
                            }
                            alt="hs1"
                          />
                          <p>Left View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images
                                ? pdf?.images?.right_image
                                : right_view
                            }
                            alt="hs1"
                          />
                          <p>Right View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images
                                ? pdf?.images?.front_image
                                : front_view
                            }
                            alt="hs1"
                          />
                          <p>Front View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images ? pdf?.images?.back_image : back_view
                            }
                            alt="hs1"
                          />
                          <p>Back View</p>
                        </div>
                      </div>
                    ) : (
                      <div className="imageGrid">
                        <div className="gridItems">
                          <ImageWithError
                            src={
                              pdf?.images?.top_image
                                ? pdf?.images?.top_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? top
                                : modelChart?.value === "AAB"
                                ? top2
                                : topView
                            }
                          />
                          <p>Top View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={
                            pdf?.images
                              ? pdf?.images?.bottom_image
                              : bottmomView
                          }
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.bottom_image
                                ? pdf?.images?.bottom_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? bottom
                                : modelChart?.value === "AAB"
                                ? bottom2
                                : bottmomView
                            }
                          />
                          <p>Bottom View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={pdf?.images ? pdf?.images?.left_image : leftView}
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.left_image
                                ? pdf?.images?.left_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? left
                                : modelChart?.value === "AAB"
                                ? left2
                                : leftView
                            }
                          />
                          <p>Left View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={
                            pdf?.images ? pdf?.images?.right_image : rightView
                          }
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.right_image
                                ? pdf?.images?.right_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? right
                                : modelChart?.value === "AAB"
                                ? right2
                                : rightView
                            }
                          />
                          <p>Right View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={
                            pdf?.images ? pdf?.images?.front_image : frontView
                          }
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.front_image
                                ? pdf?.images?.front_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? front
                                : modelChart?.value === "AAB"
                                ? front2
                                : frontView
                            }
                          />
                          <p>Front View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={pdf?.images ? pdf?.images?.back_image : backView}
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.back_image
                                ? pdf?.images?.back_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? back
                                : modelChart?.value === "AAB"
                                ? back2
                                : backView
                            }
                          />
                          <p>Back View</p>
                        </div>
                      </div>
                    )}
                    <div className="page_number">
                      <span>1</span>
                    </div>
                  </div>
                </div>

                <div className="custom-chart">
                  <Header />

                  <div className="Inner_padding">
                    <div className="Energy">
                      <h1>Energy/Capacity</h1>
                    </div>
                    <div className="tableFlex2" style={{ gap: "10px" }}>
                      {/* <img src={energy} alt="Energy/Capacity" /> */}
                      <div className="EnergyChart" style={{ width: "70%" }}>
                        <ReactApexChart
                          options={state1.options as any}
                          series={state1.series as any}
                          type="line"
                          height={350}
                        />
                      </div>

                      <div className="Cyclechart" style={{ width: "30%" }}>
                        <ReactApexChart
                          options={state.options as any}
                          series={state.series as any}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </div>
                    <div className="Mobilereps">
                      <TableContainer
                        style={{
                          width: "100%",
                          borderRadius: "0px",
                          boxShadow: "none",
                        }}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableHead style={{ backgroundColor: "#3498d2" }}>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{
                                  color: "white",
                                  border: "2px solid #3498d2",
                                  fontSize: "15px",
                                }}
                              >
                                Health Indicator
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Parameter
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Units
                              </TableCell>
                            </TableRow>{" "}
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                component="th"
                                scope="row"
                              >
                                Charge Capacity 1st cycle
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_six?.charge_capacities?.[0]?.toFixed(
                                  1
                                )}{" "}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                Ah
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                component="th"
                                scope="row"
                              >
                                Charge Capacity 2nd cycle
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_six?.charge_capacities?.[1]?.toFixed(
                                  1
                                )}{" "}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                Ah
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                component="th"
                                scope="row"
                              >
                                Charge Capacity 3rd cycle
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_six?.charge_capacities?.[2]?.toFixed(
                                  1
                                )}{" "}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                Ah
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="Energy">
                      <h1>Power/Rate Capability</h1>
                    </div>
                    <div className="tableFlex2">
                      {/* <img src={poer} alt="Energy/Capacity" /> */}
                      <div style={{ width: "100%" }} className="Power_Rate ">
                        <ReactApexChart
                          options={state2.options as any}
                          series={state2.series as any}
                          type="line"
                          height={300}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "15px", marginBottom: "20px" }}>
                      <TableContainer
                        style={{
                          width: "100%",
                          borderRadius: "0px",
                          boxShadow: "none",
                        }}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableHead style={{ backgroundColor: "#3498d2" }}>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{
                                  color: "white",
                                  border: "2px solid #3498d2",
                                }}
                              >
                                Health Indicator
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                  padding: "1px",
                                }}
                                component="th"
                                scope="row"
                              >
                                Parameter
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                  padding: "1px",
                                }}
                                align="right"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                  padding: "1px",
                                }}
                                align="right"
                              >
                                Units
                              </TableCell>
                            </TableRow>{" "}
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                component="th"
                                scope="row"
                              >
                                DCIR @ 15A (V)
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                align="right"
                              >
                                {(
                                  Number(
                                    pdf?.electrical_inspection_four?.dcir_values
                                      ?.length > 0
                                      ? pdf?.electrical_inspection_four
                                          ?.dcir_values[0]
                                      : 0
                                  ) * 1000
                                )?.toFixed(2)}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                align="right"
                              >
                                mΩ
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "1px",
                                }}
                                component="th"
                                scope="row"
                              >
                                DCIR @ 30A (V)
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "1px",
                                }}
                                align="right"
                              >
                                {(
                                  Number(
                                    pdf?.electrical_inspection_four?.dcir_values
                                      ?.length > 1 &&
                                      pdf?.electrical_inspection_four
                                        ?.dcir_values[1]
                                  ) * 1000
                                )?.toFixed(2)}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "1px",
                                }}
                                align="right"
                              >
                                mΩ
                              </TableCell>
                            </TableRow>
                            <TableRow
                              style={{ padding: "1px" }}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "1px",
                                }}
                                component="th"
                                scope="row"
                              >
                                DCIR @ 60A (V)
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "1px",
                                }}
                                align="right"
                              >
                                {(
                                  Number(
                                    pdf?.electrical_inspection_four?.dcir_values
                                      ?.length > 2 &&
                                      pdf?.electrical_inspection_four
                                        ?.dcir_values[2]
                                  ) * 1000
                                ).toFixed(2)}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "1px",
                                }}
                                align="right"
                              >
                                mΩ
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="page_number">
                      <span>2</span>
                    </div>
                  </div>
                </div>

                <div className="custom-chart">
                  <Header />
                  <div className="Inner_padding">
                    <div className="Energy" style={{ marginTop: "10px" }}>
                      <h1>Electrochemical Impedance Spectroscopy(EIS)</h1>
                    </div>
                    <div className="tableFlex2">
                      {/* <img src={plot} alt="Energy/Capacity" /> */}
                      <div className="Nyquist">
                        <ReactApexChart
                          options={state3.options as any}
                          series={state3.series as any}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <TableContainer
                        style={{
                          width: "100%",
                          borderRadius: "0px",
                          boxShadow: "none",
                        }}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableHead style={{ backgroundColor: "#3498d2" }}>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{
                                  color: "white",
                                  border: "2px solid #3498d2",
                                }}
                              >
                                Health Indicator
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Parameter
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Units
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[0]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[0]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[0]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[1]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[1]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[1]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[2]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[2]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[2]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[3]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[3]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[3]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[4]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[4]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[4]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[5]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[5]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[5]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[6]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[6]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[6]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[7]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[7]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[7]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[8]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[8]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[8]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="Energy">
                      <h1>Differential Voltage Analysis (DVA)</h1>
                    </div>
                    <div className="tableFlex2" style={{ marginTop: "20px" }}>
                      {/* <img src={dva} alt="Energy/Capacity" style={{width: "460px"}} /> */}
                      <div className="DVA">
                        <ReactApexChart
                          options={state4.options as any}
                          series={state4.series as any}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                    <div className="page_number">
                      <span>3</span>
                    </div>
                  </div>
                </div>

                <div className="custom-chart" ref={page4}>
                  <Header />
                  <div className="Inner_padding">
                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                      <TableContainer
                        style={{
                          width: "100%",
                          borderRadius: "0px",
                          boxShadow: "none",
                        }}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableHead style={{ backgroundColor: "#3498d2" }}>
                            <TableRow>
                              <TableCell
                                colSpan={4}
                                style={{
                                  color: "white",
                                  border: "2px solid #3498d2",
                                  textAlign: "right",
                                }}
                              >
                                Health Indicator
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Description
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Parameter
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Units
                              </TableCell>
                            </TableRow>{" "}
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                2nd Cycle 1st Trough
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                component="th"
                                scope="row"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                  }}
                                >
                                  dQdV
                                </p>
                                <p>Capacity</p>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                align="right"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {" "}
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle 1st Trough`
                                        ]?.dQdV?.toFixed(1)
                                      : "-"
                                  }`}
                                </p>
                                <p>
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle 1st Trough`
                                        ]?.Capacity?.toFixed(1)
                                      : "-"
                                  }`}
                                </p>
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                (dQ/dV,Ah)
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                2nd Cycle Last Peak
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                component="th"
                                scope="row"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                  }}
                                >
                                  dQdV
                                </p>
                                <p>Capacity</p>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                align="right"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle last Peak`
                                        ]?.dQdV?.toFixed(1)
                                      : "-"
                                  }`}
                                  <br />
                                </p>

                                <p>
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle last Peak`
                                        ]?.Capacity?.toFixed(1)
                                      : "-"
                                  }`}
                                </p>
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                (dQ/dV,Ah)
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="Energy2" style={{ marginTop: "20px" }}>
                      <h1>Terms and Conditions</h1>
                    </div>
                    <div className="paragraph">
                      <p>
                        Samsar Resources expressly disclaims any responsibility
                        for all damage, injury, harm, cost, expense, or
                        liability arising out of or related, in whole or in
                        part, to the use or misuse of the battery which is
                        subject to this state of health.
                      </p>
                      <br />
                      <p>
                        To the fullest extent allowable by applicable law, this
                        state of health is provided without warranty of any kind
                        whether express, implied, or statutory including, but
                        not limited to, warranties of merchantability and
                        warranty of fitness for a particular purpose.
                      </p>
                      <br />
                      <p>
                        Samsar Resources is not and will not be liable under any
                        legal theory including but not limited to actions
                        sounding warranty, contract, strict liability, tort,
                        negligence, and/or misrepresentation for any and all
                        damage, injury, harm, cost, expense, or liability
                        arising out of or related, in whole or in part, to the
                        use or misuse of the battery which is subject to this
                        state of health.
                      </p>
                      <br />
                      <p>
                        Samsar Resources is not and will not be liable for
                        damage to property, loss of business, loss of income,
                        special damages, incidental damages, consequential
                        damages, punitive damages, or exemplary damages, whether
                        foreseeable or unforeseeable.
                      </p>
                      <br />
                      <p>
                        In no event will Samsar Resources' liability exceed the
                        value of the Li-Ion battery for which this statement of
                        health is issued.
                      </p>
                      <br />
                      <p>
                        Important: Please read and follow all safety warnings
                        and advisories issued by the manufacturer of the battery
                        for which this health certification is issued. Contact
                        the manufacturer to obtain all such safety warnings and
                        advisories.
                      </p>
                    </div>

                    <div className="page_number4">
                      <span>4</span>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        ) : (
          <div
            id="container"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "100%" }}>
              <Grid container justifyContent={"center"}>
                <div className="custom-chart">
                  <Header />
                  <div className="Inner_padding">
                    <Grid
                      item
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <h2
                        style={{
                          width: "80%",
                          fontSize: "35px",
                          textAlign: "center",
                          fontWeight: "700",
                          color: "#3498d2",
                          marginInline: "auto",
                        }}
                      >
                        Module Id: {pdf?.module_id}
                      </h2>
                      {pdf?.oem == "Jaguar Land Rover" ? (
                        <div
                          className="Report_desc"
                          style={{
                            marginTop: "20px",
                            width: "100%",
                            marginInline: "auto",
                            textAlign: "center",
                          }}
                        >
                          The Device Under Test is a{" "}
                          <span style={{ fontWeight: "800" }}>
                            Jaguar Land Rover X590{" "}
                          </span>{" "}
                          module.{" "}
                          {isOemSerialId(pdf) ? (
                            <>
                              {`The Samsar Module ID `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` is associated with the OEM ID of `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.oem_serial_id}.{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              {`The OEM ID for the Samsar Module ID `}{" "}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` was not legible to retrieve.`}
                            </>
                          )}
                          <br />
                        </div>
                      ) : (
                        <div
                          className="Report_desc"
                          style={{
                            marginTop: "20px",
                            width: "100%",
                            marginInline: "auto",
                            textAlign: "center",
                          }}
                        >
                          The Device Under Test is a{" "}
                          <span style={{ fontWeight: "800" }}>{pdf?.oem}</span>
                          {pdf?.oem !== "Caterpillar" && " "}
                          {pdf?.oem !== "Caterpillar" && pdf?.oem_model}.{" "}
                          {/* which was deemed fit for further
                        evaluation at an Open Circuit Voltage of {pdf?.ocv} V
                        with an internal ACIR at 1kHz equal to {pdf?.acir} m.{" "} */}
                          {isOemSerialId(pdf) ? (
                            <>
                              {`The Samsar Module ID `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` is associated with the OEM ID of `}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.oem_serial_id}.{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              {`The OEM ID for the Samsar Module ID `}{" "}
                              <span style={{ fontWeight: "700" }}>
                                {pdf?.module_id}
                              </span>{" "}
                              {` was not legible to retrieve.`}
                            </>
                          )}
                          <br />
                          {/* Below are the snapshot of each phase the battery module
                    followed by its health analysis. */}
                        </div>
                      )}
                    </Grid>
                    <div className="tableFlex" style={{ gap: "10px" }}>
                      {pdf?.oem == "Jaguar Land Rover" ? (
                        <TableContainer
                          style={{
                            width: "50%",
                            borderRadius: "0px",
                            boxShadow: "none",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    width: "100%",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Rated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Rated Capacity @C/4, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  232
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Maximum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  12.6
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Nominal
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  10.8
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Minimum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  7.5
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <TableContainer
                          style={{
                            width: "50%",
                            borderRadius: "0px",
                            boxShadow: "none",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    width: "100%",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Rated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Rated Capacity @C/3, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar"
                                    ? "105"
                                    : pdf?.oem === "Jaguar Land Rover"
                                    ? "232"
                                    : pdf?.oem === "Nissan" &&
                                      pdf?.oem_model === "Gen 4"
                                    ? "112"
                                    : pdf?.oem === "Nissan" &&
                                      pdf?.oem_model === "Gen 2"
                                    ? "66"
                                    : "84"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Maximum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar" ? "58.8" : "8.4"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Nominal
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar" ? "50" : "7.2"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  component="th"
                                  scope="row"
                                >
                                  Voltage: Minimum
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.oem === "Caterpillar" ? "42" : "5"}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  V
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}

                      {/* table 2 */}
                      {pdf?.oem == "Jaguar Land Rover" ? (
                        <TableContainer
                          style={{
                            width: "50%",
                            boxShadow: "none",
                            borderRadius: "0px",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                color: "white",
                                boxShadow: "none",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Estimated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Observed Capacity @C/4, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? Number(
                                        pdf?.electrical_inspection_six?.estimated_specification[0][
                                          "Observed Capacity @C/3, 25°C"
                                        ]?.split(" ")[0]
                                      ).toFixed(1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                {" "}
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Estimated SOH
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]?.split(".")[0] +
                                      "." +
                                      pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]
                                        ?.split(".")[1]
                                        .slice(0, 1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  %
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <TableContainer
                          style={{
                            width: "50%",
                            boxShadow: "none",
                            borderRadius: "0px",
                          }}
                          component={Paper}
                        >
                          <Table size="small" aria-label="a dense table">
                            <TableHead
                              style={{
                                backgroundColor: "#3498d2",
                                color: "white",
                                boxShadow: "none",
                                width: "100%",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  style={{
                                    color: "white",
                                    border: "2px solid #3498d2",
                                  }}
                                >
                                  Estimated Specifications
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Parameter
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Value
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    fontWeight: "600",
                                  }}
                                  align="right"
                                >
                                  Units
                                </TableCell>
                              </TableRow>{" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Observed Capacity @C/3, 25°C
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? Number(
                                        pdf?.electrical_inspection_six?.estimated_specification[0][
                                          "Observed Capacity @C/3, 25°C"
                                        ]?.split(" ")[0]
                                      ).toFixed(1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  Ah
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                {" "}
                                <TableCell
                                  style={{
                                    border: "2px solid #3498d2",
                                    height: "56px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  Estimated SOH
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  {pdf?.electrical_inspection_six
                                    ?.estimated_specification?.length > 0
                                    ? pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]?.split(".")[0] +
                                      "." +
                                      pdf?.electrical_inspection_six?.estimated_specification[0][
                                        "Estimated SOH"
                                      ]
                                        ?.split(".")[1]
                                        .slice(0, 1)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  style={{ border: "2px solid #3498d2" }}
                                  align="right"
                                >
                                  %
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </div>
                    {pdf?.oem == "Jaguar Land Rover" ? (
                      <div className="imageGrid">
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images?.top_image
                                ? pdf?.images?.top_image
                                : top_view
                            }
                            alt="hs1"
                          />
                          <p>Top View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images
                                ? pdf?.images?.bottom_image
                                : bottmom_view
                            }
                            alt="hs1"
                          />
                          <p>Bottom View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images ? pdf?.images?.left_image : left_view
                            }
                            alt="hs1"
                          />
                          <p>Left View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images
                                ? pdf?.images?.right_image
                                : right_view
                            }
                            alt="hs1"
                          />
                          <p>Right View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images
                                ? pdf?.images?.front_image
                                : front_view
                            }
                            alt="hs1"
                          />
                          <p>Front View</p>
                        </div>
                        <div className="gridItems">
                          <img
                            src={
                              pdf?.images ? pdf?.images?.back_image : back_view
                            }
                            alt="hs1"
                          />
                          <p>Back View</p>
                        </div>
                      </div>
                    ) : (
                      <div className="imageGrid">
                        <div className="gridItems">
                          <ImageWithError
                            src={
                              pdf?.images?.top_image
                                ? pdf?.images?.top_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? top
                                : modelChart?.value === "AAB"
                                ? top2
                                : topView
                            }
                          />
                          <p>Top View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={
                            pdf?.images
                              ? pdf?.images?.bottom_image
                              : bottmomView
                          }
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.bottom_image
                                ? pdf?.images?.bottom_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? bottom
                                : modelChart?.value === "AAB"
                                ? bottom2
                                : bottmomView
                            }
                          />
                          <p>Bottom View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={pdf?.images ? pdf?.images?.left_image : leftView}
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.left_image
                                ? pdf?.images?.left_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? left
                                : modelChart?.value === "AAB"
                                ? left2
                                : leftView
                            }
                          />
                          <p>Left View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={
                            pdf?.images ? pdf?.images?.right_image : rightView
                          }
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.right_image
                                ? pdf?.images?.right_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? right
                                : modelChart?.value === "AAB"
                                ? right2
                                : rightView
                            }
                          />
                          <p>Right View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={
                            pdf?.images ? pdf?.images?.front_image : frontView
                          }
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.front_image
                                ? pdf?.images?.front_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? front
                                : modelChart?.value === "AAB"
                                ? front2
                                : frontView
                            }
                          />
                          <p>Front View</p>
                        </div>
                        <div className="gridItems">
                          {/* <img
                          src={pdf?.images ? pdf?.images?.back_image : backView}
                          alt="hs1"
                        /> */}
                          <ImageWithError
                            src={
                              pdf?.images?.back_image
                                ? pdf?.images?.back_image
                                : topView
                            }
                            alt="hs1"
                            fallbackSrc={
                              modelChart?.value === "AAD"
                                ? back
                                : modelChart?.value === "AAB"
                                ? back2
                                : backView
                            }
                          />
                          <p>Back View</p>
                        </div>
                      </div>
                    )}
                    <div className="page_number">
                      <span>1</span>
                    </div>
                  </div>
                </div>

                <div className="custom-chart">
                  <Header />

                  <div className="Inner_padding">
                    <div className="Energy">
                      <h1>Energy/Capacity</h1>
                    </div>
                    <div className="tableFlex2" style={{ gap: "10px" }}>
                      {/* <img src={energy} alt="Energy/Capacity" /> */}
                      <div className="EnergyChart" style={{ width: "70%" }}>
                        <ReactApexChart
                          options={state1.options as any}
                          series={state1.series as any}
                          type="line"
                          height={350}
                        />
                      </div>

                      <div className="Cyclechart" style={{ width: "30%" }}>
                        <ReactApexChart
                          options={state.options as any}
                          series={state.series as any}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </div>
                    <div className="Mobilereps">
                      <TableContainer
                        style={{
                          width: "100%",
                          borderRadius: "0px",
                          boxShadow: "none",
                        }}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableHead style={{ backgroundColor: "#3498d2" }}>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{
                                  color: "white",
                                  border: "2px solid #3498d2",
                                  fontSize: "15px",
                                }}
                              >
                                Health Indicator
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Parameter
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Units
                              </TableCell>
                            </TableRow>{" "}
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                component="th"
                                scope="row"
                              >
                                Charge Capacity 1st cycle
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_six?.charge_capacities?.[0]?.toFixed(
                                  1
                                )}{" "}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                Ah
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                component="th"
                                scope="row"
                              >
                                Charge Capacity 2nd cycle
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_six?.charge_capacities?.[1]?.toFixed(
                                  1
                                )}{" "}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                Ah
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                component="th"
                                scope="row"
                              >
                                Charge Capacity 3rd cycle
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_six?.charge_capacities?.[2]?.toFixed(
                                  1
                                )}{" "}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontSize: "12px",
                                }}
                                align="right"
                              >
                                Ah
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    {/* <div className="Energy">
                    <h1>Power/Rate Capability</h1>
                  </div>
                  <div className="tableFlex2">
                    <div style={{ width: "100%" }} className="Power_Rate ">
                      <ReactApexChart
                        options={state2.options as any}
                        series={state2.series as any}
                        type="line"
                        height={300}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "15px", marginBottom: "20px" }}>
                    <TableContainer
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                        boxShadow: "none",
                      }}
                      component={Paper}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#3498d2" }}>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{
                                color: "white",
                                border: "2px solid #3498d2",
                              }}
                            >
                              Health Indicator
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                                padding: "1px",
                              }}
                              component="th"
                              scope="row"
                            >
                              Parameter
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                                padding: "1px",
                              }}
                              align="right"
                            >
                              Value
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                fontWeight: "600",
                                padding: "1px",
                              }}
                              align="right"
                            >
                              Units
                            </TableCell>
                          </TableRow>{" "}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "0px",
                              }}
                              component="th"
                              scope="row"
                            >
                              DCIR @ 15A (V)
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "0px",
                              }}
                              align="right"
                            >
                              {(
                                Number(
                                  pdf?.electrical_inspection_four?.dcir_values
                                    ?.length > 0
                                    ? pdf?.electrical_inspection_four
                                        ?.dcir_values[0]
                                    : 0
                                ) * 1000
                              )?.toFixed(2)}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "0px",
                              }}
                              align="right"
                            >
                              mΩ
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "1px",
                              }}
                              component="th"
                              scope="row"
                            >
                              DCIR @ 30A (V)
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "1px",
                              }}
                              align="right"
                            >
                              {(
                                Number(
                                  pdf?.electrical_inspection_four?.dcir_values
                                    ?.length > 1 &&
                                    pdf?.electrical_inspection_four
                                      ?.dcir_values[1]
                                ) * 1000
                              )?.toFixed(2)}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "1px",
                              }}
                              align="right"
                            >
                              mΩ
                            </TableCell>
                          </TableRow>
                          <TableRow
                            style={{ padding: "1px" }}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "1px",
                              }}
                              component="th"
                              scope="row"
                            >
                              DCIR @ 60A (V)
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "1px",
                              }}
                              align="right"
                            >
                              {(
                                Number(
                                  pdf?.electrical_inspection_four?.dcir_values
                                    ?.length > 2 &&
                                    pdf?.electrical_inspection_four
                                      ?.dcir_values[2]
                                ) * 1000
                              ).toFixed(2)}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                padding: "1px",
                              }}
                              align="right"
                            >
                              mΩ
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div> */}
                    <div className="Energy" style={{ marginTop: "10px" }}>
                      <h1>Electrochemical Impedance Spectroscopy(EIS)</h1>
                    </div>
                    <div className="tableFlex2">
                      {/* <img src={plot} alt="Energy/Capacity" /> */}
                      <div className="Nyquist">
                        <ReactApexChart
                          options={state3.options as any}
                          series={state3.series as any}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                    <div className="page_number">
                      <span>2</span>
                    </div>
                  </div>
                </div>

                <div className="custom-chart">
                  <Header />
                  <div className="Inner_padding">
                    <div style={{ marginTop: "10px" }}>
                      <TableContainer
                        style={{
                          width: "100%",
                          borderRadius: "0px",
                          boxShadow: "none",
                        }}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableHead style={{ backgroundColor: "#3498d2" }}>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{
                                  color: "white",
                                  border: "2px solid #3498d2",
                                }}
                              >
                                Health Indicator
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Parameter
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Units
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[0]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[0]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[0]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[1]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[1]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[1]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[2]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[2]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[2]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[3]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[3]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[3]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[4]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[4]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[4]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[5]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[5]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[5]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[6]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[6]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[6]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[7]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[7]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[7]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[8]?.[0]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[8]?.[1]
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                {pdf?.electrical_inspection_five?.eis_parameters
                                  ? pdf?.electrical_inspection_five
                                      ?.eis_parameters[8]?.[2]
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="Energy">
                      <h1>Differential Voltage Analysis (DVA)</h1>
                    </div>
                    <div className="tableFlex2" style={{ marginTop: "20px" }}>
                      {/* <img src={dva} alt="Energy/Capacity" style={{width: "460px"}} /> */}
                      <div className="DVA">
                        <ReactApexChart
                          options={state4.options as any}
                          series={state4.series as any}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                      <TableContainer
                        style={{
                          width: "100%",
                          borderRadius: "0px",
                          boxShadow: "none",
                        }}
                        component={Paper}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableHead style={{ backgroundColor: "#3498d2" }}>
                            <TableRow>
                              <TableCell
                                colSpan={4}
                                style={{
                                  color: "white",
                                  border: "2px solid #3498d2",
                                  textAlign: "right",
                                }}
                              >
                                Health Indicator
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Description
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                component="th"
                                scope="row"
                              >
                                Parameter
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Value
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  fontWeight: "600",
                                }}
                                align="right"
                              >
                                Units
                              </TableCell>
                            </TableRow>{" "}
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                2nd Cycle 1st Trough
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                component="th"
                                scope="row"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                  }}
                                >
                                  dQdV
                                </p>
                                <p>Capacity</p>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                align="right"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {" "}
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle 1st Trough`
                                        ]?.dQdV?.toFixed(1)
                                      : "-"
                                  }`}
                                </p>
                                <p>
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle 1st Trough`
                                        ]?.Capacity?.toFixed(1)
                                      : "-"
                                  }`}
                                </p>
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                (dQ/dV,Ah)
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                component="th"
                                scope="row"
                              >
                                2nd Cycle Last Peak
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                component="th"
                                scope="row"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                  }}
                                >
                                  dQdV
                                </p>
                                <p>Capacity</p>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "2px solid #3498d2",
                                  padding: "0px",
                                }}
                                align="right"
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #3498d2",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle last Peak`
                                        ]?.dQdV?.toFixed(1)
                                      : "-"
                                  }`}
                                  <br />
                                </p>

                                <p>
                                  {`${
                                    pdf?.electrical_inspection_six
                                      ?.health_parameters
                                      ? pdf?.electrical_inspection_six?.health_parameters?.[0]?.[
                                          `(dQ/dV,Ah) 2nd Cycle last Peak`
                                        ]?.Capacity?.toFixed(1)
                                      : "-"
                                  }`}
                                </p>
                              </TableCell>
                              <TableCell
                                style={{ border: "2px solid #3498d2" }}
                                align="right"
                              >
                                (dQ/dV,Ah)
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="page_number">
                      <span>3</span>
                    </div>
                  </div>
                </div>

                <div className="custom-chart" ref={page4}>
                  <Header />
                  <div className="Inner_padding">
                    <div className="Energy2" style={{ marginTop: "20px" }}>
                      <h1>Terms and Conditions</h1>
                    </div>
                    <div className="paragraph">
                      <p>
                        Samsar Resources expressly disclaims any responsibility
                        for all damage, injury, harm, cost, expense, or
                        liability arising out of or related, in whole or in
                        part, to the use or misuse of the battery which is
                        subject to this state of health.
                      </p>
                      <br />
                      <p>
                        To the fullest extent allowable by applicable law, this
                        state of health is provided without warranty of any kind
                        whether express, implied, or statutory including, but
                        not limited to, warranties of merchantability and
                        warranty of fitness for a particular purpose.
                      </p>
                      <br />
                      <p>
                        Samsar Resources is not and will not be liable under any
                        legal theory including but not limited to actions
                        sounding warranty, contract, strict liability, tort,
                        negligence, and/or misrepresentation for any and all
                        damage, injury, harm, cost, expense, or liability
                        arising out of or related, in whole or in part, to the
                        use or misuse of the battery which is subject to this
                        state of health.
                      </p>
                      <br />
                      <p>
                        Samsar Resources is not and will not be liable for
                        damage to property, loss of business, loss of income,
                        special damages, incidental damages, consequential
                        damages, punitive damages, or exemplary damages, whether
                        foreseeable or unforeseeable.
                      </p>
                      <br />
                      <p>
                        In no event will Samsar Resources' liability exceed the
                        value of the Li-Ion battery for which this statement of
                        health is issued.
                      </p>
                      <br />
                      <p>
                        Important: Please read and follow all safety warnings
                        and advisories issued by the manufacturer of the battery
                        for which this health certification is issued. Contact
                        the manufacturer to obtain all such safety warnings and
                        advisories.
                      </p>
                    </div>

                    <div className="page_number4">
                      <span>4</span>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        ))}

      <div style={{ width: 800, height: "0", opacity: "0" }}>
        {loading &&
          hisMesuments.map((measure: any, index: number) => {
            return (
              <ReactApexChart
                key={index}
                options={{
                  ...chartOption[measure.type],
                  chart: {
                    ...chartOption[measure.type].chart,
                    id: `chart_${index}`,
                  },
                  xaxis: {
                    ...chartOption[measure.type].xaxis,
                    categories: measure.x_axis,
                  },
                }}
                series={measure.series}
                type="line"
              />
            );
          })}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="sm-pdfModal"
      >
        <div className="custom_pdf_download">
          <div className="pdf_model_header">
            <p>Report</p>
            {/* {hisDataURI && reportData && (
              <div>
                <PDFDownloadLink
                  document={
                    <PDFContent
                      hisData={hisDataURI}
                      reportData={reportData}
                      module_id={moduleId}
                    />
                  }
                  fileName="fee_acceptance.pdf"
                >
                  {/* {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download now!"
                  } */}
            {/* "Download now!"
                </PDFDownloadLink>
              </div> */}
            <div className="cancel_header">
              <Button
                className="white-closeBtn"
                onClick={() => setOpen(false)}
              ></Button>
            </div>
          </div>
          <div className="pdf_content">
            {hisDataURI && reportData && (
              <PDFViewer
                width={"100%"}
                height={"630"}
                style={{ margin: "0 auto" }}
              >
                <PDFContent
                  hisData={hisDataURI}
                  reportData={reportData}
                  module_id={moduleId}
                />
              </PDFViewer>
            )}
          </div>
        </div>
      </Modal>
    </FormControl>
  );
};
