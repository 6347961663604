import { toast } from "react-toastify";
import {
  errorHandler,
  forminstance,
  jsoninstance,
  jsoninstanceModule,
  jsoninstancenew,
  responseHandler,
} from ".";
import { ModulePacking } from "../page/inspection/types";

export const getPallets = async (token: string) => {
  jsoninstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstance
    .get("/item-list")
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const getUser = async (token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get("/myprofile")
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createLotService = async (palletData: any, token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/shipment-lot/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getLotService = async (token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get("/factory/shipment-lot")
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createPallet = async (palletData: any, token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/inspected-pallet/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getPallet = async (token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get("/factory/inspected-pallet")
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createDailyPrep = async (oemData: any, token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/battery-module", oemData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createInspectionOneService = async (
  palletData: any,
  token: string
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/electrical-inspection-one/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createInspectionTwoService = async (
  palletData: any,
  token: string
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/electrical-inspection-two/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createInspectionThreeService = async (
  palletData: any,
  token: string
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/electrical-inspection-three/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createInspectionFourService = async (
  palletData: any,
  token: string
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/electrical-inspection-four/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createInspectionFiveService = async (
  palletData: any,
  token: string
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/electrical-inspection-five/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createInspectionSixService = async (
  palletData: any,
  token: string
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/electrical-inspection-six/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createBinLog = async (palletData: any, token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/bin-log/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const getModulePackaging = async (
  token: string,
  packagingData: ModulePacking
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  jsoninstancenew.defaults.timeout = 300000; // 300 seconds
  return jsoninstancenew
    .get("/factory/module-packaging", { params: packagingData })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const createModulePackaging = async (body: any, token: string) => {
  jsoninstanceModule.defaults.headers.common.Authorization = `Bearer ${token}`;
  // jsoninstancenew.defaults.timeout = 300000; // 300 seconds
  return jsoninstanceModule
    .post("/factory/module-packaging/", body)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const createSortingAndBinningProcess = async (
  palletData: any,
  token: string
) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post("/factory/module-packaging/", palletData)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

// export const getInspectionFourService = async (token: string) => {
//   forminstance.defaults.headers.common.Authorization = `Bearer ${token}`
//   return forminstance
//     .get("/factory/electrical-inspection-four/")
//     .then((res) => {
//       return responseHandler(res)
//     })
//     .catch((err) => {
//       return errorHandler(err)
//     })
// }

// export const getInspectionFiveService = async (token: string) => {
//   forminstance.defaults.headers.common.Authorization = `Bearer ${token}`
//   return forminstance
//     .get("/factory/electrical-inspection-five/")
//     .then((res) => {
//       return responseHandler(res)
//     })
//     .catch((err) => {
//       return errorHandler(err)
//     })
// }

// export const getInspectionSixService = async (token: string) => {
//   forminstance.defaults.headers.common.Authorization = `Bearer ${token}`
//   return forminstance
//     .get("/factory/electrical-inspection-six/")
//     .then((res) => {
//       return responseHandler(res)
//     })
//     .catch((err) => {
//       return errorHandler(err)
//     })
// }

export const getReportData = async (token: string, module_id: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get(`/factory/battery-module-report/${module_id}/`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const getOEMModelData = async (token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get(`/factory/oem-and-oem-model/`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getContractorData = async (token: string, params: any) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get(`/factory/contractor`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getContractorLocationData = async (token: string, params: any) => {
  // jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get(`/factory/contractorLocation`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const getBatteryMOdules = async (token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get(`/factory/battery-module/`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const getBinDetailsService = async (token: string, module_id: any) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .get(`/factory/sorting-binning-process/?module_id=${module_id}`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const binnigSortingService = async (token: string, param: any) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;
  return jsoninstancenew
    .post(`/factory/sorting-binning-process/confirmBinId`, param)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getOEM = async () => {
  return jsoninstancenew
    .get(`/factory/kpi/oem`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getModel = async () => {
  return jsoninstancenew
    .get(`/factory/kpi/oemModel`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getReportModuleId = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/report/module/list`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getPackList = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/qis/pack/list`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const getModuleIdData = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/single-report/`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getModuleIdDataReport = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/report/`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getPackIdDataReport = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/qis/report`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getCustomerPackId = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/module-packaging/list`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getGraph1 = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/kpi/graph`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getGraphLast = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/kpi/contribution`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const postVendor = async (data: any) => {
  return jsoninstancenew
    .post(`/factory/vendor`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getGraph3 = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/kpi/graph/inventory`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getGraph4 = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/kpi/facility`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getCount = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/kpi/count`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getGraph5 = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/kpi/user`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getGraph2 = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/kpi/defects`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getPassport = async (id: any) => {
  return jsoninstancenew
    .get(`/factory/passport/` + id)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getPassportList = async () => {
  return jsoninstancenew
    .get(`/factory/passport/getpackid`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getModuleById = async (id: any) => {
  return jsoninstancenew
    .get(`/factory/battery_status/` + id)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getBatteryData = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/battery`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getSignUrl = async (params: any) => {
  return jsoninstancenew
    .get(`/generate-url`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getPermission = async (token: string) => {
  jsoninstancenew.defaults.headers.common.Authorization = `Bearer ${token}`;

  return jsoninstancenew
    .get(`/api/permission`)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getScheduleData = async (params: any) => {
  return jsoninstancenew
    .get(`/admin/target`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getBulkReport = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/bulk-report`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const skipHisFour = async (params: any) => {
  return jsoninstancenew

    .get(`/factory/electrical-inspection-four/skip`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};

export const postSupport = async (data: any) => {
  return jsoninstancenew
    .post(`/support`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const qisModuleInspection = async (data: any) => {
  return jsoninstancenew
    .post(`/factory/qis/module-inspection`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const qisModuleOne = async (data: any) => {
  return jsoninstancenew
    .post(`/factory/qis/one`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const qisModuleTwo = async (data: any) => {
  return jsoninstancenew
    .post(`/factory/qis/two`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const qisModuleThree = async (data: any) => {
  return jsoninstancenew
    .post(`/factory/qis/three`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const qisModuleFour = async (data: any) => {
  return jsoninstancenew
    .post(`/factory/qis/four`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const qisModulePackaging = async (data: any) => {
  return jsoninstancenew
    .post(`/factory/qis/pack-packaging`, data)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getQisId = async (id: any) => {
  return jsoninstancenew
    .get(`/factory/qis/` + id)
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
// QIS
export const getFacilityQis = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/qis/kpi/facility`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getCountQis = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/qis/kpi/count`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getCountGraph = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/qis/kpi/graph`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
export const getCountContribution = async (params: any) => {
  return jsoninstancenew
    .get(`/factory/qis/kpi/contribution`, { params })
    .then((res) => {
      return responseHandler(res);
    })
    .catch((err) => {
      return errorHandler(err);
    });
};
